import supabase from './supabaseconnect';

export async function claim_details_api(claim_id) {
    // console.log(claim_id)
    var { error } = await supabase.auth.refreshSession();

    var { data, error } = await supabase.from('claim').select('*').eq('id', claim_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var claim_details = data;

        var { data, error } = await supabase.from('user').select('*').eq('user_id', claim_details[0].user_id).eq('status','1');

        if (error) {
            var resepnse_data = { "success": false, "msg": error.message }
            return (resepnse_data);
        } else {
            if(data.length > 0){
                claim_details[0].created_by = data[0].first_name + ' ' + data[0].last_name
                claim_details[0].email_address = data[0].email_address
            }else{
                claim_details[0].created_by = ''
                claim_details[0].email_address = ''
            }
            return get_upload_file(claim_id, claim_details);
        }
    }
}

export async function get_upload_file(claim_id, claim_details) {
    const { data: signedUrl, error } = await supabase.storage.from('maxoninvoices').createSignedUrl(claim_id, 6000);

    if(signedUrl){
        claim_details[0].invoice_file = signedUrl;
    }else{
        claim_details[0].invoice_file = null;
    }

    return repair_list(claim_id, claim_details);
}

export async function repair_list(claim_id, claim_details) {
    var { data, error } = await supabase.from('repair_details').select('*').eq('claim_id', claim_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var repair_list = data;
        return parts_list(claim_id, claim_details, repair_list);
    }
}

export async function parts_list(claim_id, claim_details, repair_list) {
    var { data, error } = await supabase.from('parts').select('*').eq('claim_id', claim_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var final_claim_details = [];

        if(claim_details[0].total_repair_amount != null && claim_details[0].total_repair_amount != ''){
            claim_details[0].total_repair_amount = parseFloat(claim_details[0].total_repair_amount).toFixed(2);
        }

        if(claim_details[0].total_parts_amount != null && claim_details[0].total_parts_amount != ''){
            claim_details[0].total_parts_amount = parseFloat(claim_details[0].total_parts_amount).toFixed(2);
        }

        if(claim_details[0].total_misc_charge != null && claim_details[0].total_misc_charge != ''){
            claim_details[0].total_misc_charge = parseFloat(claim_details[0].total_misc_charge).toFixed(2);
        }

        if(claim_details[0].total_claim_amount != null && claim_details[0].total_claim_amount != ''){
            claim_details[0].total_claim_amount = parseFloat(claim_details[0].total_claim_amount).toFixed(2);
        }

        if (data.length > 0) {
            var parts_list_arr = [];
            repair_list.forEach((repair,index) => {

                if(repair.labour_hours != null && repair.labour_hours != '' && repair.labour_hours != 'undefined' && repair.labour_hours != undefined){
                    repair.labour_hours = parseFloat(repair.labour_hours).toFixed(2);
                }

                if(repair.labour_rate != null && repair.labour_rate != '' && repair.labour_rate != 'undefined' && repair.labour_rate != undefined){
                    repair.labour_rate = parseFloat(repair.labour_rate).toFixed(2);
                }

                if(repair.labour_total != null && repair.labour_total != '' && repair.labour_total != 'undefined' && repair.labour_total != undefined){
                    repair.labour_total = parseFloat(repair.labour_total).toFixed(2);
                }

                if(repair.misc_charge != null && repair.misc_charge != '' && repair.misc_charge != 'undefined' && repair.misc_charge != undefined){
                    repair.misc_charge = parseFloat(repair.misc_charge).toFixed(2);
                }else{
                    repair.misc_charge = parseFloat(0.00).toFixed(2);
                }

                if(repair.repair_parts_total != null && repair.repair_parts_total != '' && repair.repair_parts_total != 'undefined' && repair.repair_parts_total != undefined){
                    repair.repair_parts_total = parseFloat(repair.repair_parts_total).toFixed(2);
                }

                var part_count = 0;
                data.forEach(parts => {
                    if (repair.id == parts.repair_id) {

                        if(parts.qty_claimed != null && parts.qty_claimed != '' && parts.qty_claimed != 'undefined' && parts.qty_claimed != undefined){
                            parts.qty_claimed = parseFloat(parts.qty_claimed).toFixed(2);
                        }

                        if(parts.unit_price_claimed != null && parts.unit_price_claimed != '' && parts.unit_price_claimed != 'undefined' && parts.unit_price_claimed != undefined){
                            parts.unit_price_claimed = parseFloat(parts.unit_price_claimed).toFixed(2);
                        }

                        if(parts.total != null && parts.total != '' && parts.total != 'undefined' && parts.total != undefined){
                            parts.total = parseFloat(parts.total).toFixed(2);
                        }

                        parts.repair_no = index + 1;
                        part_count = part_count + 1;
                        parts.part_count = part_count;
                        parts_list_arr.push(parts);
                    }
                });
                // repair.parts_list = parts_list_arr;
            });
            claim_details[0].repair_list = repair_list
            claim_details[0].parts_list = parts_list_arr
            final_claim_details = claim_details;
        } else {

            repair_list.forEach((repair,index) => {

                if(repair.labour_hours != null && repair.labour_hours != '' && repair.labour_hours != 'undefined' && repair.labour_hours != undefined){
                    repair.labour_hours = parseFloat(repair.labour_hours).toFixed(2);
                }

                if(repair.labour_rate != null && repair.labour_rate != '' && repair.labour_rate != 'undefined' && repair.labour_rate != undefined){
                    repair.labour_rate = parseFloat(repair.labour_rate).toFixed(2);
                }

                if(repair.labour_total != null && repair.labour_total != '' && repair.labour_total != 'undefined' && repair.labour_total != undefined){
                    repair.labour_total = parseFloat(repair.labour_total).toFixed(2);
                }

                if(repair.misc_charge != null && repair.misc_charge != '' && repair.misc_charge != 'undefined' && repair.misc_charge != undefined){
                    repair.misc_charge = parseFloat(repair.misc_charge).toFixed(2);
                }else{
                    repair.misc_charge = parseFloat(0.00).toFixed(2);
                }

                if(repair.repair_parts_total != null && repair.repair_parts_total != '' && repair.repair_parts_total != 'undefined' && repair.repair_parts_total != undefined){
                    repair.repair_parts_total = parseFloat(repair.repair_parts_total).toFixed(2);
                }
            });

            claim_details[0].repair_list = repair_list
            claim_details[0].parts_list = []
            final_claim_details = claim_details;
        }

        // console.log(JSON.stringify(final_claim_details));
        var resepnse_data = { "success": true, "msg": "Calim Details Successfully", "data": final_claim_details }
        return (resepnse_data);
    }
}
