import React from "react";
import LEFTMENU from "./leftMenu";
import search_icon from '../assets/search_icon.png'
import { useForm } from 'react-hook-form';
import filter_btn from "../assets/filter.png"
import { useEffect, useState } from "react";
import supabase from "../api/supabaseconnect.js";
import GIFLOADER from '../assets/loader.gif';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import edit_icon from "../assets/editing.png"
import { get_rma_list_api } from "../api/rma_list_api.js";
import moment from "moment";




function Returnlist() {

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm();
    const [show, setShow] = useState(false);
    const [session, setSession] = useState(null)
    var [enableloader, SetBtnDisable] = useState(false);
    const [returnslist, SetReturnslist] = useState([]);
    const [searchValue, setSearchValue] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setrecordsPerPage] = useState(5);
    const [items_per_page, setItems_per_page] = useState(0);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const allRecords = returnslist.slice(firstIndex, lastIndex);
    const npage = Math.ceil(returnslist.length / recordsPerPage);
    const pnumbers = [...Array(npage + 1).keys()].slice(1);
    const navigate = useNavigate();
    const [order, setOrder] = useState("ASC");
    const [filterSearch, SetfilterSearch] = useState('');
    const [filterDate, SetfilterDate] = useState([]);
    const [toggle, SetToggle] = useState(1);
    const [dates, SetDates] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [filterStatus, SetfilterStatus] = useState([{}])

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        var countfilteritmrma = JSON.parse(localStorage.getItem('rtnlstfilterdata'));
        if (countfilteritmrma != undefined && countfilteritmrma != '' && countfilteritmrma != null) {
            SetfilterStatus(countfilteritmrma)
            // setChecked(!checked)
        } else {
            countfilteritmrma = ''
        }
        if (countfilteritmrma && countfilteritmrma.draft === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('draft', countfilteritmrma.draft === true)
        }
        if (countfilteritmrma && countfilteritmrma.submitted === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('submitted', countfilteritmrma.submitted === true)
        }
        if (countfilteritmrma && countfilteritmrma.waitingonreturn === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('waitingonreturn', countfilteritmrma.waitingonreturn === true)
        }
        if (countfilteritmrma && countfilteritmrma.closed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('closed', countfilteritmrma.closed === true)
        }
        if (countfilteritmrma && countfilteritmrma.rejected === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('rejected', countfilteritmrma.rejected === true)
        }
        if (countfilteritmrma && countfilteritmrma.declined === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('declined', countfilteritmrma.declined === true)
        }
        if (countfilteritmrma && countfilteritmrma.creditprocessed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('creditprocessed', countfilteritmrma.creditprocessed === true)
        }
        if (countfilteritmrma && countfilteritmrma.inprocess === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('inprocess', countfilteritmrma.inprocess === true)
        }
        var splitdaterma = [];
        var filterdaterma = (localStorage.getItem('rtnlstfildate'));
        console.log(filterdaterma)
        if (filterdaterma != undefined && filterdaterma != '' && filterdaterma != null) {
            var splitdaterma = filterdaterma.split(',');
            console.log(splitdaterma)
            setStartDate(splitdaterma[0]);
            setEndDate(splitdaterma[1]);
            SetfilterDate(splitdaterma)
            if (splitdaterma != []) {
                document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            }
            // setChecked(!checked)
        } else {
            filterdaterma = []
        }
        var response_data = get_rma_list_api('', splitdaterma, '', '', countfilteritmrma).then(res => {
            console.log(res.data)
            SetReturnslist(res.data)
            SetBtnDisable(true);
            var crtpage = localStorage.getItem('fromdetails')
            var countperpage = localStorage.getItem('currentpage');
            if (crtpage == 'pagecount' && countperpage != undefined && countperpage != '') {
                setrecordsPerPage(countperpage);
            }
            var claim_page_start = localStorage.getItem('returnlist_page_start');
            var claim_curr_page = localStorage.getItem('returnlist_curr_page');

            if (claim_page_start != undefined && claim_page_start != '' && claim_curr_page != undefined && claim_curr_page != '') {
                setItems_per_page(parseInt(claim_page_start));
                setCurrentPage(parseInt(claim_curr_page));
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    function updateToggle(id) {
        SetToggle(id);
    }
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const filterSubmit = (req_data) => {
        console.log(req_data)
        SetfilterStatus(req_data)
        document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
        localStorage.setItem('returnlist_page_start', 0)
        localStorage.setItem('returnlist_curr_page', 1)
        setItems_per_page(0)
        setCurrentPage(1)
        SetfilterDate(dates);
        setShow(!show)
        SetBtnDisable(false);
        dates.push(startDate)
        dates.push(endDate)
        // console.log(dates)
        var response_data = get_rma_list_api('', dates, '', '', req_data).then(res => {
            console.log(res)
            SetReturnslist(res.data);
            SetBtnDisable(true);
            SetDates([]);
            localStorage.setItem('rtnlstfilterdata', JSON.stringify(req_data))
            if (dates != undefined && dates != '' && dates != null && dates[0] != undefined && dates[1] != undefined) {
                localStorage.setItem('rtnlstfildate', dates)
            }
        })
    }
    function ClearFilter() {
        reset();
        setShow(!show)
        document.getElementById("divsection").style.backgroundColor = "white";
        localStorage.removeItem('rtnlstfilterdata');
        localStorage.removeItem('rtnlstfildate');
        localStorage.setItem('returnlist_page_start', 0)
        localStorage.setItem('returnlist_curr_page', 1)
        SetBtnDisable(false);
        // SetDates([])
        SetfilterDate([]);
        setCurrentPage(1)
        SetfilterStatus({});
        setItems_per_page(0)
        setStartDate()
        setEndDate()
        var response_data = get_rma_list_api('', '', '', '', '').then(res => {
            SetReturnslist(res.data);
            SetBtnDisable(true);
            // setStartDate(new Date())
        })
    }
    const sorting = (col) => {
        console.log(col)
        setCurrentPage(1)
        setItems_per_page(0)
        localStorage.setItem('returnlist_page_start', 0)
        localStorage.setItem('returnlist_curr_page', 1)
        // setOrder('ASC');
        if (order === 'ASC') {
            var response_data = get_rma_list_api(filterSearch, filterDate, col, order, filterStatus).then(res => {
                console.log(res)
                SetReturnslist(res.data)
            })
            setOrder('DSC');
        }
        if (order === 'DSC') {
            var response_data = get_rma_list_api(filterSearch, filterDate, col, order, filterStatus).then(res => {
                SetReturnslist(res.data)
            })
            setOrder('ASC');
        }
    }
    function pageChange(evt) {
        setCurrentPage(1);
        setItems_per_page(0)
        setrecordsPerPage(evt.target.value);
        localStorage.setItem('currentpage', evt.target.value)
        localStorage.setItem('returnlist_page_start', 0)
        localStorage.setItem('returnlist_curr_page', 1)
    }
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            var start_ind = (currentPage - 2) * recordsPerPage;
            setItems_per_page(start_ind)
            localStorage.setItem('returnlist_page_start', start_ind)
            localStorage.setItem('returnlist_curr_page', (currentPage - 1))
        }
    }
    // currentPage
    function changeCurPage(id) {
        console.log(id)
        var start_ind = (id - 1) * recordsPerPage;
        setItems_per_page(start_ind)
        setCurrentPage(id)
    }
    // nextPage
    function nextPage() {
        if (currentPage !== npage) {
            var start_ind = currentPage * recordsPerPage;
            setItems_per_page(start_ind)
            setCurrentPage(currentPage + 1)
            localStorage.setItem('returnlist_page_start', start_ind)
            localStorage.setItem('returnlist_curr_page', (currentPage + 1))
        }
    }
    function editPage(evt) {
        return navigate(`/rmaedit/${evt}`);

    }
    function details_page(res, status) {
        console.log(status)
        if (status == "0") {
            return navigate(`/rmaedit/${res}`);
        } else {
            return navigate(`/rmadetails/${res}`);
        }
    }

    var search_res = watch('user_search')
    useEffect(() => {
        // SetfilterSearch(searchResult)
        setCurrentPage(1);
        setItems_per_page(0)
        console.log(search_res);
        const delayApiCall = setTimeout(() => {
            var response_data = get_rma_list_api(search_res, filterDate, '', '', filterStatus).then(res => {
                console.log(res.data)
                SetReturnslist(res.data)
            })
        }, 1000);
        return () => clearTimeout(delayApiCall);
    }, [search_res])
    const isEmpty = returnslist.length === 0;
    return (
        <div className="userlist_total">
            <LEFTMENU />

            <div className="container details_All">
                <div hidden={enableloader} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                <div className="claim_top_bar">
                    <div className="user_topbar">
                        <div className="">
                            <h3>
                                RMA</h3>
                        </div>
                        <div className="userSearch">
                            <div className="control_search">
                                <form className="search-area">
                                    <img src={search_icon} alt="search-icon" />
                                    <input type="text"
                                        placeholder="Search..."{...register('user_search')} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="claim_top_btns user_top_bar">
                    <div className="control_search"></div>
                    <div className="claimBtns">
                        <button className="filter_btnn" onClick={() => setShow(!show)} id="divsection">
                            <img src={filter_btn}></img> Filter</button>
                    </div>
                </div>
                <div className="filter_popTotal">
                    {
                        show && <div className="claimPop filres">
                            <div className="claim-popTotal">
                                <div className="popSubclass">
                                    <div className="claim-pop_left">
                                        <div>
                                            <ul>
                                                <li onClick={() => updateToggle(1)}>Status</li>
                                                <li onClick={() => updateToggle(2)}>Date</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="claim-pop_right">
                                        <form onSubmit={handleSubmit(filterSubmit)}>
                                            <div className={toggle === 1 ? "show-content" : "content"}>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" id="draft"  {...register("draft")} />
                                                    <label htmlFor="draft" className="form-check-label" >
                                                        Draft
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="submitted" {...register("submitted")} />
                                                    <label htmlFor="submitted" className="form-check-label" >
                                                        Submitted
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="waitingonreturn" {...register("waitingonreturn")} />
                                                    <label htmlFor="waitingonreturn" className="form-check-label" >
                                                        Waiting on Return
                                                    </label>
                                                </div>
                                                {/* <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="entryhold" {...register("entryhold")} />
                                                    <label htmlFor="entryhold" className="form-check-label" >
                                                        Entry Hold
                                                    </label>
                                                </div> */}
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="closed" {...register("closed")} />
                                                    <label htmlFor="closed" className="form-check-label" >
                                                        Closed
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="declined" {...register("declined")} />
                                                    <label htmlFor="declined" className="form-check-label" >
                                                        Declined
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="creditprocessed" {...register("creditprocessed")} />
                                                    <label htmlFor="creditprocessed" className="form-check-label" >
                                                        Credit Processed
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="inprocess" {...register("inprocess")} />
                                                    <label htmlFor="inprocess" className="form-check-label" >
                                                        In Process
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={toggle === 2 ? "show-content" : "content"}>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <div className="filter_btns ">
                                                <button className="clr_btn" onClick={ClearFilter}>Clear</button>
                                                <button className="filCan" onClick={() => setShow(!show)}>Cancel </button>
                                                <button type="submit" className="addclaim-sub-btn"  >Apply </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="users_table table-responsive">
                    {isEmpty ? (
                        <p className="display_text">No returns to show</p>
                    ) : (
                        <table className="table desc_table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => sorting('rma_no')}>
                                        <b>RMA ID</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('return_date')}>
                                        <b>Claim Date</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('status_value')}>
                                        <b>Status</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allRecords && allRecords?.map((res, index) =>
                                    <tr key={res.id}>
                                        <td>{(items_per_page + index + 1)}</td>
                                        <td >{res.rma_no}</td>
                                        <td >{moment(res.return_date).format("MM-DD-YYYY")}</td>
                                        <td onClick={() => details_page(res.id, res.status)}>
                                            {(() => {
                                                if (res.status_value === "Draft") {
                                                    return <p className="draft_color">Draft</p>;
                                                } else if (res.status_value === "Submitted") {
                                                    return <div className="admin_rma_sub_color">Submitted
                                                        <span class="tooltiptext-closed">
                                                            <strong>Status: Submitted</strong> <br />
                                                            Your claim was submitted and is now under review awaiting further processing.</span>
                                                    </div>
                                                } else if (res.status_value === "Declined") {
                                                    return <div className="admin_dec_color">Declined
                                                        <span class="tooltiptext-closed">
                                                            <strong>Status: Declined</strong> <br />
                                                            Your RMA has been declined. A Maxon Customer Service Rep will contact you
                                                            for further support.</span>
                                                    </div>
                                                } else if (res.status_value === "Closed") {
                                                    return <div className="admin_rma_sub_color">Closed
                                                        <span class="tooltiptext-closed">
                                                            <strong>Status: Closed</strong> <br />
                                                            Your RMA has been successfully completed and is now closed. Thank you for
                                                            your patience.</span>
                                                    </div>
                                                } else if (res.status_value === "Waiting on Return") {
                                                    return <div className="admin_wait_color">Waiting on Return
                                                        <span class="tooltiptext-closed">
                                                            <strong>Status: Waiting on Return</strong> <br />
                                                            Your RMA was approved. Please ensure the return part is sent within 30 days
                                                            from RMA issue date to avoid automatic cancellation of your claim.</span>
                                                    </div>
                                                } else if (res.status_value === "In Process") {
                                                    return <div className="admin_dec_color">In Process
                                                        <span class="tooltiptext-closed">
                                                            <strong>Status: In Process</strong> <br />
                                                            Your returned item has been received and is currently being inspected. We will
                                                            update you once the process is complete.</span>
                                                    </div>
                                                } else if (res.status_value === "Credit Processed") {
                                                    return <div className="admin_dec_color">Credit Processed
                                                        <span class="tooltiptext-closed">
                                                            <strong>Status: Credit Processed</strong> <br />
                                                            Your RMA has been accepted and a credit memo has been
                                                            processed.</span>
                                                    </div>
                                                } else if (res.status_value === "Failed") {
                                                    return <p className="del_color">Failed</p>;
                                                } else {
                                                    return <p>{res.status_value}</p>
                                                }
                                            })()}
                                        </td>
                                        <td >
                                            {(() => {
                                                if (res.status === 0) {
                                                    return <div className="">
                                                        <img className="rtn_edit" src={edit_icon} onClick={() => editPage(res.id)} ></img>
                                                    </div>
                                                } else {
                                                    return <button className="view_btn_rtn" onClick={() => details_page(res.id, res.status)}>View</button>
                                                }
                                            })()}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                    <div className="mob_card">
                        <div className="card_data">
                            {allRecords?.map((res, index) =>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="Card_top">
                                            <div>
                                                {(() => {
                                                    if (res.status_value === "Draft") {
                                                        return <p className="draft_color">
                                                            <ul> <li>
                                                                Draft
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Submitted") {
                                                        return <p className="sub_color">
                                                            <ul><li>
                                                                Submitted
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Closed") {
                                                        return <p className="sub_color">
                                                            <ul><li>
                                                                Closed
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Failed") {
                                                        return <p className="del_color">
                                                            <ul><li>
                                                                Failed
                                                            </li></ul>
                                                        </p>;
                                                    } else {
                                                        return <p>{res.status_value}</p>
                                                    }
                                                })()}
                                            </div>
                                            <div className="edit_icon_card">
                                                {(() => {
                                                    if (res.status === 0) {
                                                        return <div className="rtndelimg">
                                                            <img src={edit_icon} onClick={() => editPage(res.id)} ></img>

                                                        </div>
                                                    } else {
                                                        return <span onClick={() => details_page(res.id, res.status)}>View</span>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        <div className="row card_top"  >
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>RMA ID </label>
                                                    {res.rma_no}
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Claim Date</label>
                                                    {moment(res.return_date).format("MM-DD-YYYY")}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="claim_pagination userListPagi">
                        <div className="itemsPage custom-select usePagination">
                            <p>Items Per Page:</p>
                            <select value={recordsPerPage} className="form-select" onChange={(e) => pageChange(e)}>
                                <option >5</option>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <div className="page_wid">
                            <span className="User_count">Returns ({returnslist.length})</span>
                            <nav className="user_Ui">
                                <ul className="pagination ">
                                    <li className="page-item">
                                        <a className="page-link"
                                            onClick={prePage}>Prev</a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link"
                                            onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Returnlist;