import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import '../pages/Addclaim.css';
import "../pages/editclaim.css";
import LEFTMENU from "./leftMenu.js";
import DraftBTNIcon from '../assets/save-draft-icon.png';
import SubmitBTNIcon from '../assets/sub-btn-icon.png';
import CanCelBtn from '../assets/cancel-claim-btn.png';
import * as Yup from 'yup';
import GIFLOADER from '../assets/loader.gif';
import {
    category_list_api, parts_list_search_api,
    gate_type_list_api, component_list_api, validate_serial_api, hours_list_api, parts_listing_api, get_web_reference_no_api, get_part_description_api, get_part_number_api
} from "../api/meta_data_api.js";
import * as moment from 'moment';
import { edit_claim_api } from '../api/edit_claim_api.js';
import back_icon from "../assets/back_icon.png"
import { Link, useNavigate } from 'react-router-dom';
import { claim_details_api, repair_list, parts_list } from "../api/edit_claim_details_api.js";
import { useParams } from "react-router-dom";
import Topmenu from './topmenu.js';
import axios from 'axios';
import backnew from "../assets/backnew.svg"
import newlogout from '../assets/logoutblack.svg';


const schema = Yup.object().shape({

    web_reference_number: Yup.string()
        .required('Web reference number is required'),
    web_entry_date: Yup.date().default(function () {
        return new Date();
    }),
    serial_no: Yup.string()
        .required('Serial number is required'),
    repair_date: Yup.string()
        .required('Date of Birth is required'),
    // .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
    invoice_no: Yup.string()
        .required('Invoice number is required'),
    cycle_count: Yup.string().required('Cycle Count is required'),
    invoice_date: Yup.string()
        .required('Invoice date is required'),
    model_no: Yup.string(),
    company_name: Yup.string()
        .required('Company name is required'),
    purchase_date: Yup.string()
        .required('Purchase date is required'),
    repair_list: Yup.array().of(
        Yup.object().shape({
            category: Yup.string().required("Category is required"),
            gate_type: Yup.string().required("Gatetype is required"),
            component: Yup.string().required("Component is required"),
            labour_hours: Yup.number()
                .required("Labor Hours is required")
                .positive('Positive numbers only allowed'),
            labour_rate: Yup.number()
                .required("Labor rate is required")
                .positive('Positive numbers only allowed'),
            labour_total: Yup.number().required("Labor total is required").positive('Positive numbers only allowed'),
            misc_charge: Yup.number().positive().integer().required(),
            // misc_description: Yup.string().required("Misc Description is required"),
            // repair_Description: Yup.string().required("Repair Desc is required"),
            repair_parts_total: Yup.number().required("Repair Parts Total is required"),
        })
    ),
    known_option: Yup.string(),
    parts_list: Yup.array().of(
        Yup.object().shape({
            repair_no: Yup.string(),
            part_number: Yup.string(),
            // .required("Part Number is required"),
            parts_description: Yup.string(),
            // .required("Part Description is required"),
            quantity_claimed: Yup.string(),
            // .required("Quantity Claim is required"),
            unit_price_claimed: Yup.string(),
            // .required("Unit Price Claim is required"),
            partstotal: Yup.string()
            // .required("Total is required"),
        })
    )
});

const EditCheck = () => {
    const { register, handleSubmit, control, unregister, getValues, reset, watch, setValue, resetField, setFocus, formState: { errors } } = useForm({
        mode: "onBlur",
        defaultValues: {
            loadState: "unloaded",
            known_option: "Part No"
        }
    });

    const { fields: repair_list, append: appendRepair, remove: removeRepair, insert: insertRepair } = useFieldArray({
        control,
        name: 'repairs',
    });
    const { fields: parts_list, append: appendPart, remove: removePart, insert: insertPart } = useFieldArray({
        control,
        name: 'parts',
    });
    const [activeRepairIndex, setActiveRepairIndex] = useState(0);
    const [activePartIndex, setActivePartIndex] = useState(0);
    let [repairs, setRepairs] = useState([{
        category: '', gate_type: '', component: '', labour_hours: '', labour_rate: '', labour_total: '', misc_charge: '', misc_description: '', repair_Description: '', repair_parts_total: '', parts: [{
            repairpart: '0-0',
            repair_no: '',
            part_number: '',
            parts_description: '',
            quantity_claimed: '',
            unit_price_claimed: '',
            partstotal: ''
        }]
    }]);
    let [curRepairPartList, setcurRepairPartList] = useState([{
        repairpart: '0-0',
        repair_no: '',
        part_number: '',
        parts_description: '',
        quantity_claimed: '',
        unit_price_claimed: '',
        partstotal: ''
    }]);
    const navigate = useNavigate();

    const SerialNo_Valid = watch('serial_no');

    var repair_category_id = "repairs." + activeRepairIndex + ".category";

    const current_repaircat_id = watch(repair_category_id);

    var repair_gate_id = "repairs." + activeRepairIndex + ".gate_type";

    const current_repairgate_id = watch(repair_gate_id);

    var repair_component_id = "repairs." + activeRepairIndex + ".component";

    const current_repaircompo_id = watch(repair_component_id);

    var repair_rate = "repairs." + activeRepairIndex + ".labour_rate";

    const current_repairrate_val = watch(repair_rate);

    var repair_laborHour = "repairs." + activeRepairIndex + ".labour_hours";

    const current_repairhour_value = watch(repair_laborHour);

    var repair_laborTot = "repairs." + activeRepairIndex + ".labour_total";

    var repairParts_Tot = "repairs." + activeRepairIndex + ".repair_parts_total";



    var repair_MiscCharge = "repairs." + activeRepairIndex + ".misc_charge";

    const current_repairmisc_char_val = watch(repair_MiscCharge);



    var Parts_No = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".part_number";
    // console.log(Parts_No);
    const current_part_no_val = watch(Parts_No);

    // var Parts_DESC = "repairs." + activeRepairIndex + ".parts."+activePartIndex+".part_description";
    // console.log(Parts_No);
    // const current_part_no_val = watch(Parts_DESC);

    var Parts_description_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".parts_description";
    // console.log(Parts_No);
    const current_partdesc_val = watch(Parts_description_val);


    var Quantity_claim_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".quantity_claimed";
    // console.log(Parts_No);
    const current_QuantityPrice_val = watch(Quantity_claim_val);

    var Unitprice_claim_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".unit_price_claimed";
    // console.log(Parts_No);
    const current_UnitPrice_val = watch(Unitprice_claim_val);

    var KnownOptionVal = "known_option";
    // console.log(Parts_No);
    const current_KnownOptionVal = watch(KnownOptionVal);

    var TotalQU_val = "repairs." + activeRepairIndex + ".parts." + activePartIndex + ".partstotal";
    const invofile_upload = watch('invoice_file');

    var [suchiddenDraft, ShowSucDraft] = useState(true);
    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(false);

    var [suWebMSG, SetsuWebMSG] = useState(false);
    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');

    var [btndis, SetBtnDisable] = useState(false);
    const [isWarrentyExpired, setisWarrentyExpired] = useState(false);

    // const [isHourChangedMsg, setisHourChangedMsg] = useState(false);
    var [category_list, SetCategoryList] = useState([{}]);
    var [gate_type_list, SetGateTypeList] = useState([{}]);
    var [component, SetComponent] = useState([{}]);
    var [laborhours, SetLaborHours] = useState();
    var [repDate, SetrepDate] = useState(new Date());
    var [partCount, SetPartCount] = useState(1);
    var [repairCount, SetRepairCount] = useState(1);
    var [totalCount, SetTotalCount] = useState(2);
    var [isSeValidMsg, setisSeiralValidMsg] = useState(true);
    var [serialNopartdesc, SetSerialNoPartDesc] = useState('');
    var [serialNocompanyname, SetSerialNoCompanyName] = useState('');
    var [serialNopurchaseDate, SetSerialpurchaseDate] = useState();
    var [NewWEbnoMSG, SetNewWEbnoMSG] = useState('');
    var [known_optionVal, Setknown_optionVal] = useState('Part No');
    const [known_value, setknown_value] = useState("Part No");
    const [isFileValid, SetIsFileValid] = useState('');
    var [repairGatetype, SetrepairGatetype] = useState();
    var [repairComponent, SetrepairComponent] = useState();
    var [repairTabChange, SetrepairTabChange] = useState(false);
    var [openEdit, SetopenEdit] = useState(true);

    var [laborRate, SetlaborRate] = useState(0);

    var [laborCalcTotal, SetlaborCalcTotal] = useState(0);
    var [repairpartstotalCalc, SetrepairpartstotalCalc] = useState();

    var [WebRefNo, SetWebRefNo] = useState('');

    var [PART_DESC, SetPART_DESC] = useState();
    var [PART_NUMBER, SetPART_NUMBER] = useState('');
    var [QuantityClaim, SetQuantityClaim] = useState('');
    var [UnitPriceClaim, SetUnitPriceClaim] = useState('');

    var [QuantityunitTotalClaim, SetQuantityunitTotalClaim] = useState('');

    var [partLists, SetpartLists] = useState([{}]);

    var [IsMiscFieldValid, SetIsMiscFieldValid] = useState(false);

    var [IsAllFieldValid, SetIsAllFieldValid] = useState(true);
    const [show, setShow] = useState(false);
    var { id } = useParams();
    const [claimDetails, SetClaimDetails] = useState([{}]);
    var [cteindex, Setcteindex] = useState(0);

    var [invoice_file_edit, Setinvoice_file_edit] = useState(null);
    var [labhourtotal, setLabhourTotal] = useState();
    var [repairTotal, setrepairTotal] = useState();
    var [dateValid, setDateValid] = useState(false);
    var [invodate, setInvodate] = useState(false);
    var [crutdate, setCrutdate] = useState(false);
    var [recrutdate, setRecrutdate] = useState(false);
    const exceptThisSymbols = ["e"];
    var [fileerr, setFileErr] = useState(false);
    const [activeyear, setActiveYear] = useState();
    var [fileerrsize, setFileErrsize] = useState(false);
    var [redirectpop, setredirectpop] = useState(false);
    const [popshowerr, setPopShowerr] = useState(false);
    const [showError, setshowError] = useState();

    useEffect(() => {
        var response_data = claim_details_api(id).then(res => {
            SetBtnDisable(btndis = false);
            console.log(res.data[0]);
            if (res.data[0].status == 1) {
                // return navigate(`/claimDetails/${id}`);
                setredirectpop(true)
            }
            if (res.data[0].status_value == 'Error') {
                setPopShowerr(!popshowerr)
                var splitpart = res.data[0].bulk_claim_error_msg.split('.');
                const descdata = splitpart.pop()
                console.log(descdata);
                setshowError(splitpart)
                SetBtnDisable(btndis = true);
            }
            if (res.data[0].repair_list == undefined || res.data[0].repair_list.length == 0) {
                res.data[0].repair_list = [{
                    category: '', gate_type: '', component: '', labour_hours: '', labour_rate: '', labour_total: '', misc_charge: '', misc_description: '', repair_Description: '', repair_parts_total: '', parts: [{
                        repairpart: '0-0',
                        repair_no: '',
                        part_number: '',
                        parts_description: '',
                        quantity_claimed: '',
                        unit_price_claimed: '',
                        partstotal: ''
                    }]
                }];
            }
            const dateObj = new Date();
            const year = dateObj.getFullYear();
            setActiveYear(year)
            setRepairs(res.data[0].repair_list);
            if (res.data[0].repair_list[0].parts == undefined || res.data[0].repair_list[0].parts.length == 0) {
                res.data[0].repair_list[0].parts = [{
                    repairpart: '0-0',
                    repair_no: '',
                    part_number: '',
                    parts_description: '',
                    quantity_claimed: '',
                    unit_price_claimed: '',
                    partstotal: ''
                }]
            }
            console.log(res.data[0])
            res.data[0].repair_list.forEach((e, Rindex) => {
                var Repair_Cate = "repairs." + Rindex + ".category"
                setValue(Repair_Cate, e.category_id);
                var Repair_Gate = "repairs." + Rindex + ".gate_type"
                setValue(Repair_Gate, e.gate_type_id);
                var Repair_Comp = "repairs." + Rindex + ".component"
                setValue(Repair_Comp, e.component_id);
                var Repair_laourhr = "repairs." + Rindex + ".labour_hours"
                setValue(Repair_laourhr, e.labour_hours);
                var Repair_Ratee = "repairs." + Rindex + ".labour_rate"
                setValue(Repair_Ratee, e.labour_rate);
                var Repair_LabTotal = "repairs." + Rindex + ".labour_total"
                setValue(Repair_LabTotal, e.labour_total);
                var Repair_Misc = "repairs." + Rindex + ".misc_charge"
                setValue(Repair_Misc, e.misc_charge);
                var Repair_Mdesc = "repairs." + Rindex + ".misc_description"
                setValue(Repair_Mdesc, e.misc_description);
                var Repair_Rdesc = "repairs." + Rindex + ".repair_Description"
                setValue(Repair_Rdesc, e.repair_Description);
                setTimeout(() => {
                    var Repair_Rtotal = "repairs." + Rindex + ".repair_parts_total"
                    setValue(Repair_Rtotal, e.repair_parts_total);
                }, 2000);

                if (e.parts && e.parts.length > 0) {
                    e.parts.forEach((val, Pindex) => {
                        var Parts_No = "repairs." + Rindex + ".parts." + Pindex + ".part_number";
                        var Parts_Desc = "repairs." + Rindex + ".parts." + Pindex + ".parts_description";
                        var Parts_qty = "repairs." + Rindex + ".parts." + Pindex + ".quantity_claimed";
                        var Parts_UPC = "repairs." + Rindex + ".parts." + Pindex + ".unit_price_claimed";
                        var Parts_Total = "repairs." + Rindex + ".parts." + Pindex + ".partstotal";
                        setValue(Parts_No, val.part_no);
                        setValue(Parts_Desc, val.parts_description);
                        setValue(Parts_qty, val.qty_claimed);
                        setValue(Parts_UPC, val.unit_price_claimed);
                        setValue(Parts_Total, val.total);

                        val.part_number = val.part_no
                        val.quantity_claimed = val.qty_claimed
                    })
                }
                Setcteindex(cteindex++);
            })
            Setcteindex(0);
            setcurRepairPartList(res.data[0].repair_list[0].parts);
            SetClaimDetails(res.data);
            setRepairs(res.data[0].repair_list);
            setTimeout(() => {
                setValue("invoice_date", res.data[0].invoice_date);
                // setValue("partstotal", res.data[0].repair_list[0].parts[0].partstotal);
                // setValue("repair_parts_total", res.data[0].repair_list[0].repair_parts_total);
                // setrepairTotal(res.data[0].repair_list[0].repair_parts_total)
                setValue("misc_charge", res.data[0].repair_list[0].misc_charge);
                // setMiscchareset(res.data[0].repair_list[0].misc_charge)
                // setLabhourTotal(res.data[0].repair_list[0].labour_total)
            }, 1000);
            // setValue("repairs", res.data[0].repair_list)
            setValue("serial_no", res.data[0].serial_no);
            setValue("web_entry_date", res.data[0].web_entry_date);
            setValue("repair_date", res.data[0].repair_date);
            setValue("invoice_no", res.data[0].invoice_no);
            setValue("cycle_count", res.data[0].cycle_count);
            setValue("repairs_count", res.data[0].no_of_repairs);
            setValue("total_claim", res.data[0].total_claim);
            setValue("parts", res.data[0].no_of_parts);
            setValue("labour_hours", res.data[0].repair_list[0].labour_hours);
            setValue("labour_rate", res.data[0].repair_list[0].labour_rate);
            // setValue("labour_total", res.data[0].repair_list[0].labour_total);
            setValue("misc_description", res.data[0].repair_list[0].misc_description);
            setValue("repair_Description", res.data[0].repair_list[0].repair_Description);
            if (res.data[0].invoice_file) {
                Setinvoice_file_edit(res.data[0].invoice_file.signedUrl);
                setValue("invoice_file", res.data[0].invoice_file.signedUrl)
            }
            // setMiscCharge(res.data[0].repair_list[0].misc_charge)

            SetseSerialNo(res.data[0].claim_expiry_date)
            setSerPartNum(res.data[0].partNum);

            // setValue("part_number", res.data[0].repair_list[0].parts[0].part_no);
            // setValue("parts_description", res.data[0].repair_list[0].parts[0].parts_description);
            // setValue("quantity_claimed", res.data[0].repair_list[0].parts[0].qty_claimed);
            // setValue("unit_price_claimed", res.data[0].repair_list[0].parts[0].unit_price_claimed);

            SetRepairCount(parseInt(res.data[0].no_of_repairs))
            SetPartCount(parseInt(res.data[0].no_of_parts))
            SetTotalCount(parseInt(res.data[0].total_claim))
            setknown_value('Part No');
            SetBtnDisable(btndis = true);
        })
    }, [])

    function gotoview() {
        return navigate(`/claimDetails/${id}`);
    }
    
    var req_dataSaveDraft;

    var [file_invoice, SetFileInvoice] = useState(null);
    var [file_invoiceremove, SetFileInvoiceRemove] = useState(true);
    var [ISfile_removed, SetISfile_removed] = useState(false);

    const InvoiceFileRemove = () => {
        console.log('file removed')
        SetFileInvoice(null);
        SetFileInvoiceRemove(false);
        Setinvoice_file_edit(null);
        SetISfile_removed(true);
    }

    // const FileOnchange = (event) => {
    //     if (event.target.files) {
    //         console.log(event.target.files[0]);
    //         SetFileInvoice(event.target.files[0]);
    //         SetISfile_removed(true);
    //     }
    // }
    // const FileOnchange = (event) => {
    //     if (event.target.files) {
    //         var fileExt = event.target.files[0]?.name.split('.').pop();
    //         // console.log(fileExt)
    //         if (fileExt == 'pdf' || fileExt == 'png' || fileExt == 'jpg' || fileExt == 'jpeg') {
    //             console.log(event.target.files[0]);
    //             SetFileInvoice(event.target.files[0]);
    //             SetISfile_removed(true);
    //             setFileErr(false)
    //         } else {
    //             setFileErr(true)
    //             setValue('invoice_file', '')
    //         }
    //     }
    //     else {
    //         console.log('file else')
    //         SetFileInvoice(null)
    //     }
    // }
    useEffect(() => {
        console.log(invofile_upload)
        // return
        if (invofile_upload != undefined && invofile_upload != '' && invofile_upload != null && invofile_upload.length == 1) {
            console.log(invofile_upload[0].name)
            var fileExt = invofile_upload[0].name.toLowerCase().split('.').pop();
            console.log(fileExt)
            if (fileExt == 'pdf' || fileExt == 'png' || fileExt == 'jpg' || fileExt == 'jpeg') {
                const maxFileSize = 15 * 1024 * 1024; // 15MB limit
                if (invofile_upload[0].size > maxFileSize) {
                    setFileErrsize(true)
                    setFileErr(false)
                    setValue('invoice_file', '')
                } else {
                    console.log(invofile_upload[0]);
                    SetFileInvoice(invofile_upload[0]);
                    setFileErr(false)
                    setFileErrsize(false)
                }
            } else {
                setFileErr(true)
                setValue('invoice_file', '')
            }
        }
        else {
            SetFileInvoice(null)
        }
    }, [invofile_upload])
    const saveDraft = async (event) => {
        console.log("dat..........a", event.target.value);
        SetIsAllFieldValid(false);
        event.preventDefault();

        // return
        req_dataSaveDraft = getValues();
        req_dataSaveDraft.invoice_file = file_invoice;
        req_dataSaveDraft.is_upload = true;
        console.log(req_dataSaveDraft);
        // return
        const curDDate = moment(new Date()).format("MM-DD-YYYY");
        req_dataSaveDraft = getValues();
        req_dataSaveDraft.web_entry_date = curDDate;
        req_dataSaveDraft.claim_expiry_date = seSerialNo;
        req_dataSaveDraft.partNum = serPartNum;
        req_dataSaveDraft.claim_id = id;
        req_dataSaveDraft.status = 0;
        req_dataSaveDraft.is_file_removed = ISfile_removed;
        if (req_dataSaveDraft.serial_no === '' || isSeValidMsg === true) {
            // event.target.querySelector('#serialnothis').focus();
            setFocus('serial_no');
        } else {
            SetBtnDisable(btndis = false);
            req_dataSaveDraft.status = 0;
            // console.log('Status', req_dataSaveDraft);
            if (IsPartRemoved) {
                allIncPartValues.claim_expiry_date = seSerialNo;
                allIncPartValues.partNum = serPartNum;
                var response_data = await edit_claim_api(allIncPartValues);
            } else {
                var response_data = await edit_claim_api(req_dataSaveDraft);
            }
            console.log('draft claim ', response_data);
            if (response_data.success) {
                ShowSuc(suchiddenDraft = true);
                if (response_data.new_web_reference_no === '') {
                    ShowSucDraft(!suchiddenDraft);
                    setTimeout(() => {
                        ShowSuc(suchiddenDraft = true);
                        SetBtnDisable(btndis = true);
                        return navigate("/claims");
                    }, 1000);
                    reset();
                }
                else {
                    // SetsuWebMSG(true);
                    ShowSucDraft(false)
                    // SetNewWEbnoMSG(response_data.new_web_reference_no);
                    setTimeout(() => {
                        SetBtnDisable(btndis = true);
                    }, 2000)
                    setTimeout(() => {
                        // ShowSuc(suchidden = true);
                        reset();
                        setisWarrentyExpired(false);
                        return navigate("/claims");
                    }, 1000);
                    // reset();
                }
            } else {
                SetErMsg(suMSG = response_data.msg);
                ShowErr(errhidden = true);
                setTimeout(() => {
                    ShowErr(errhidden = false);
                }, 15000);
                SetBtnDisable(btndis = true);
            }
            SetIsAllFieldValid(true);
        }
        SetIsAllFieldValid(true);

    }
    var repRAte = watch(repair_rate);
    useEffect(() => {
        // console.log("hours changed ",current_repairhour_value);
        // SetlaborRate(repair_rate*repRAte);
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        setValue(repairParts_Tot, (Number(repRAte * current_repairhour_value) + Number(current_repairmisc_char_val)))
        // setisHourChangedMsg(true);
        // setValue(repair_laborHour,current_repairhour_value)
        // console.log("aret changed ",repRAte);
        // SetlaborCalcTotal(current_repairhour_value*repRAte)
        var getUnitClaim = getValues(Unitprice_claim_val);
        // console.log('unit price claim ',getUnitClaim);
        // SetQuantityunitTotalClaim(getUnitClaim * current_QuantityPrice_val);
        setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));
    }, [current_repairhour_value])

    useEffect(() => {
        // console.log(current_repairrate_val);
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)));
        // setMiscchareset( mischargeset)

        if (repRAte) {
            SetlaborCalcTotal(repRAte * current_repairhour_value);
        }
        else {
            SetlaborCalcTotal(0);
        }
        // setrepairTotal(Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value))
    }, [current_repairrate_val])


    /* Category List Call & Web Ref No */
    useEffect(() => {
        window.scrollTo(0, 0);
        var cateRes = category_list_api().then(res => {
            SetCategoryList(res.data);
        })

        // var WebRef = get_web_reference_no_api().then(res => {
        //     // console.log('category List=',res.data);
        //     if (res.success) {
        //         SetWebRefNo(res.web_reference_no);
        //         setValue('web_reference_number', res.web_reference_no);
        //     }
        // });

        var partlistsRes = parts_listing_api().then(res => {
            if (res.success) {
                // console.log('PartLists ',res.data);
                res.data.forEach(element => {
                    // console.log("part desc", element);
                    element.description = element.PartNum + ' - ' + element.PartDescription;
                });
                SetpartLists(res.data);
            }
        })
    }, []);
    var [seSerialNo, SetseSerialNo] = useState();
    var [serPartNum, setSerPartNum] = useState();

    useEffect(() => {
        const request_data = {
            "serial_no": SerialNo_Valid,
        }
        const headers = { "AUTHORIZATION": process.env.REACT_APP_LAMBDA_AUTHORIZATION, 'Content-Type': 'application/json' }

        if (SerialNo_Valid) {
            axios.post(process.env.REACT_APP_VALIDATE_SERIALNO_API, request_data, { headers }).then(response => {
                console.log(response.data)
                if (response.data.status == "Success") {
                    ShowSuc(false)
                    SetSuMsg(response.data.msg)
                    SetErMsg('')
                    setTimeout(() => {
                        ShowSuc(true)
                    }, 2000);
                    SetseSerialNo(response.data.data[0].SerialNo_Date02)
                    setSerPartNum(response.data.data[0].SerialNo_PartNum)
                    var enrty_date = getValues('web_entry_date')
                    if (response.data.data[0].SerialNo_Date02 < enrty_date) {
                        setisWarrentyExpired(true);
                    } else {
                        setisWarrentyExpired(false);
                    }
                    SetSerialNoPartDesc(response.data.data[0].Part_PartDescription);
                    setValue("model_no", response.data.data[0].Part_PartDescription);
                    SetSerialNoCompanyName(response.data.data[0].CustomerEndUser_Name);
                    setValue("company_name", response.data.data[0].CustomerEndUser_Name);
                    SetSerialpurchaseDate(moment(response.data.data[0].SerialNo_Date01).format("YYYY-MM-DD"));
                    setValue("purchase_date", moment(response.data.data[0].SerialNo_Date01).format("YYYY-MM-DD"));
                    setisSeiralValidMsg(false);
                }
            }).catch(err => {
                console.log(err);
                ShowErr(true)
                setTimeout(() => {
                    ShowErr(false)
                }, 2000);
                SetErMsg(err.response.data.msg)
                SetSuMsg('')
                SetSerialNoPartDesc('');
                setValue("model_no", '');
                SetSerialNoCompanyName('');
                setValue("company_name", '');
                SetSerialpurchaseDate('');
                setValue("purchase_date", '');
                setisSeiralValidMsg(true);
            });
        }
        // var SerialNosCheck = validate_serial_api(SerialNo_Valid).then(res => {
        //     if (res.success) {
        //         console.log('sfsdfsdfsdfsdfsdf')
        //         // console.log('Expiry Date',res.data[0].ExpireDate);
        //         SetseSerialNo(res.data[0].ExpireDate);
        //         setSerPartNum(res.data[0].PartNum);
        //         var enrty_date = getValues('web_entry_date')

        //         if (res.data[0].ExpireDate < enrty_date) {
        //             setisWarrentyExpired(true);
        //             // console.log('warrenty date expired');
        //         } else {
        //             // console.log('warrenty date not expired');
        //             setisWarrentyExpired(false);
        //         }
        //         SetSerialNoPartDesc(res.data[0].PartDescription);
        //         setValue("model_no", res.data[0].PartDescription);
        //         SetSerialNoCompanyName(res.data[0].Name);
        //         setValue("company_name", res.data[0].Name);
        //         SetSerialpurchaseDate(res.data[0].PurchaseDate);
        //         setValue("purchase_date", res.data[0].PurchaseDate);
        //         setisSeiralValidMsg(false);
        //     } else {
        //         console.log('sfsdfsdfsdfsdfsdf')
        //         SetSerialNoPartDesc('');
        //         setValue("model_no", '');
        //         SetSerialNoCompanyName('');
        //         setValue("company_name", '');
        //         SetSerialpurchaseDate('');
        //         setValue("purchase_date", '');
        //         setisSeiralValidMsg(true);
        //     }
        // })
    }, [SerialNo_Valid]);

    var Val_known;
    var [checked, Setchecked] = useState('partnochecked');
    useEffect(() => {
        if (current_KnownOptionVal === "Part No") {
            Setchecked('partnochecked');
        } else {
            Setchecked('partdescchecked');
        }
        // SetPART_NUMBER('');
        // SetPART_DESC('');
        setTimeout(() => {
            setValue(Parts_No, '');
            setValue(Parts_description_val, '');
            resetField(Parts_No);
            resetField(Parts_description_val);
        }, 1000);
        // if(current_KnownOptionVal == "Part No"){
        //     Setknown_optionVal(true);
        // }else{
        //     Setknown_optionVal(false);
        // }
        // setValue('known_option',current_KnownOptionVal);
        Val_known = current_KnownOptionVal;
        // Setknown_optionVal(Val_known);
        if (Val_known !== undefined && Val_known !== '' && Val_known !== null) {
            Setknown_optionVal(Val_known);
        }
        // setValue('known_option',getValues('known_option'));
        // console.log('Known Option',getValues('known_option'));
        // console.log('Known', Val_known);

    }, [current_KnownOptionVal])

    var [Part_NoList, setPart_NoList] = useState([{}]);
    /* Part Description update based on Part No */
    useEffect(() => {
        // setPart_NoList([]);
        // console.log(known_optionVal);
        if (known_optionVal === "Part No") {
            // setPart_NoList([]);
            if (current_part_no_val != undefined && current_part_no_val.length >= 3) {
                var Part_No_List = parts_list_search_api(current_part_no_val).then(res => {
                    if (res.success) {
                        // console.log('current_part_no_val',current_part_no_val);
                        // SetPART_NUMBER(current_part_no_val);
                        // setValue(Parts_description_val, res.data[0].PartDescription);
                        // console.log(res.data);
                        setPart_NoList(res.data)
                        // SetPART_DESC(res.data[0].PartDescription);
                    }
                })
            }
            else {
                // setPart_NoList();
            }
            if (current_part_no_val != undefined && current_part_no_val.length < 3) {
                setPart_NoList([{}]);
            }
            var Part_No_Pass = get_part_description_api(current_part_no_val).then(res => {
                if (res.success) {
                    // console.log('current_part_no_val',current_part_no_val);
                    // SetPART_NUMBER(current_part_no_val);
                    setValue(Parts_description_val, res.data[0].PartDescription);
                    SetPART_DESC(res.data[0].PartDescription);
                    setValue(repair_laborTot, repRAte * current_repairhour_value);
                    setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)));
                    SetrepairpartstotalCalc((Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)))
                    SetlaborCalcTotal(repRAte * current_repairhour_value);
                    var getUnitClaim = getValues(Unitprice_claim_val);
                    setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));
                    SetQuantityunitTotalClaim((getUnitClaim * current_QuantityPrice_val));
                    // SetQuantityClaim(current_QuantityPrice_val);
                    // setValue(Quantity_claim_val,current_QuantityPrice_val);
                    // SetUnitPriceClaim(current_UnitPrice_val);
                    // setValue(Unitprice_claim_val,current_UnitPrice_val);
                }
            })
        }
    }, [current_part_no_val])

    /* Part Number update based on Part Description */
    useEffect(() => {
        if (known_optionVal === "Part Description") {

            if (current_partdesc_val != undefined && current_partdesc_val != '') {
                var splitpart = current_partdesc_val.split(' - ');
                if (splitpart.length > 1) {
                    splitpart = splitpart[1];
                    setValue(Parts_description_val, splitpart);
                }
            }

            var Part_DESC_Pass = get_part_number_api(splitpart).then(res => {
                if (res.success) {
                    // console.log(current_partdesc_val);
                    setValue(Parts_No, res.data[0].PartNum);
                    // SetPART_DESC(current_partdesc_val);
                    SetPART_NUMBER(res.data[0].PartNum);
                    // console.log('partno update based on description',res.data[0].PartNum);
                    var total_labor = getValues(repair_laborTot);

                    setValue(repair_laborTot, repRAte * current_repairhour_value);
                    var getUnitClaim = getValues(Unitprice_claim_val);
                    setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));

                    // setValue(laborCalcTotal,current_repairhour_value*repRAte);
                    // SetrepairpartstotalCalc(Number(current_repairmisc_char_val) + Number(total_labor));
                    setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(total_labor)));
                }
            })
        }
    }, [current_partdesc_val])

    useEffect(() => {
        var getUnitClaim = getValues(Unitprice_claim_val);
        // console.log('unit price claim ',getUnitClaim);
        // SetQuantityunitTotalClaim(getUnitClaim * current_QuantityPrice_val);
        setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(repRAte * current_repairhour_value)));
    }, [current_UnitPrice_val, current_QuantityPrice_val])

    /* Gate type fetch based on category ID */
    useEffect(() => {
        var gateRes = gate_type_list_api(current_repaircat_id).then(res => {
            // console.log(res.data);
            if (res.success) {
                SetGateTypeList(res.data);
                SetrepairGatetype(current_repairgate_id);

                // setValue(repair_gate_id, current_repairgate_id);
            }
        });
    }, [current_repaircat_id, current_repairgate_id])

    /* Component type fetch based on gate ID */
    useEffect(() => {
        var componentRes = component_list_api(current_repairgate_id).then(res => {
            if (res.success) {
                // console.log(res.data);
                SetComponent(res.data);
                SetrepairComponent(current_repaircompo_id);
                SetrepairTabChange(false);
                if (current_repaircat_id == '' || current_repaircat_id == undefined || current_repaircat_id == null) {
                    SetrepairComponent('')
                }
                // setValue(repair_component_id, current_repaircompo_id);
            }
        })

    }, [current_repaircompo_id, current_repairgate_id])

    /* Hours fetch 3 Dropdowns */
    useEffect(() => {
        var HoursRes = hours_list_api(current_repaircompo_id).then(res => {
            var hr; var total_labor;
            if (res.success) {

                if (openEdit) {
                    total_labor = repairs[activeRepairIndex].labour_hours * repairs[activeRepairIndex].labour_rate;
                    SetLaborHours(repairs[activeRepairIndex].labour_hours);
                    setValue(repair_laborHour, repairs[activeRepairIndex].labour_hours);
                    SetlaborCalcTotal(total_labor);
                    setValue(repair_laborTot, total_labor);
                    SetopenEdit(false);
                } else if (repairTabChange) {
                    SetrepairTabChange(false);
                    total_labor = current_repairhour_value * current_repairrate_val;
                    SetLaborHours(current_repairhour_value);
                    setValue(repair_laborHour, current_repairhour_value);
                    SetlaborCalcTotal(total_labor);
                    setValue(repair_laborTot, total_labor);
                } else {
                    hr = res.data[0].labour_hours;
                    console.log(hr);
                    total_labor = res.data[0].labour_hours * current_repairrate_val;
                    // console.log(total_labor);
                    SetLaborHours(hr);
                    if (hr != '' && hr != undefined) {
                        console.log(control)
                        if (control._formState.errors.repairs) {
                            if (control._formState.errors.repairs[activeRepairIndex].labour_hours && control._formState.errors.repairs[activeRepairIndex].labour_hours.message) {
                                control._formState.errors.repairs[activeRepairIndex].labour_hours.message = '';
                            }
                        }
                    }
                    setValue(repair_laborHour, hr);
                    SetlaborCalcTotal(total_labor);
                    setValue(repair_laborTot, total_labor);
                }
                // getValues();
            }
        })

    }, [current_repaircompo_id])
    /* Misc charge calculation */
    useEffect(() => {
        var total_labor = getValues(repair_laborTot);
        // console.log('labor total', total_labor);
        setValue(repair_laborTot, repRAte * current_repairhour_value);
        var getUnitClaim = getValues(Unitprice_claim_val);
        setValue(TotalQU_val, (getUnitClaim * current_QuantityPrice_val));

        // setValue(laborCalcTotal,current_repairhour_value*repRAte);
        // SetrepairpartstotalCalc(Number(current_repairmisc_char_val) + Number(total_labor));
        setValue(repairParts_Tot, (Number(current_repairmisc_char_val) + Number(total_labor)));
        var msid = 'mDesc' + activeRepairIndex;
        // var rpid = "rDesc" + activeRepairIndex;
        if (current_repairmisc_char_val?.length > 0) {
            document.getElementById(msid).disabled = false;
        } else {
            document.getElementById(msid).disabled = true;
            var Repair_Mdesc = "repairs." + activeRepairIndex + ".misc_description"
            // setValue(Repair_Mdesc, '');
        }
        // if (current_repairmisc_char_val?.length > 0) {
        //     document.getElementById(rpid).disabled = false;
        // } else {
        //     document.getElementById(rpid).disabled = true;
        //     var Repair_Rdesc = "repairs." + activeRepairIndex + ".repair_Description"
        //     // setValue(Repair_Rdesc, '');
        // }
    }, [current_repairmisc_char_val])


    var title = watch("repair_date");
    useEffect(() => {
        setValue('invoice_date', '');
    }, [title])

    const yesterday = moment().subtract(1, title);
    const disablePastDt = current => {
        return current.isAfter(yesterday);
    };

    var shortDate;
    const getCurrentDateInput = () => {
        const dateObj = new Date();
        // get the month in this format of 04, the same for months
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();
        shortDate = `${year}-${month}-${day}`;
        //  console.log(shortDate)
        setValue('web_entry_date', shortDate);
        return shortDate;
    };
    const numValidate = (e) => {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
    }
    const MinusValueCheck = (event) => {
        // console.log(event);
        exceptThisSymbols.includes(event.key) && event.preventDefault()
        if (event.code === 'Minus') {
            event.preventDefault();
        }

        if (event.target.value === '-') {
            event.preventDefault();
        }
    }
    var NewWEbno;
    const onSubmit = async (req_data, e) => {

        SetIsAllFieldValid(true);
        e.preventDefault();
        SetBtnDisable(btndis = false);
        console.log(req_data);
        if (req_data.repairs[activeRepairIndex].labour_hours == '') {
            // console.log(req_data.repairs[activeRepairIndex].labour_hours)
            setabourHour("Labour hour is required")
            setTimeout(() => {
                setabourHour("")
            }, 6000);
            SetBtnDisable(btndis = true);
            return
        }
        // console.log(invoice_file_edit);
        if (req_data.repair_date < serialNopurchaseDate) {
            SetBtnDisable(btndis = true);
            setDateValid(true);
            return true;
        } else if (req_data.repair_date > shortDate) {
            SetBtnDisable(btndis = true);
            setCrutdate(true);
            setTimeout(() => {
                setCrutdate(false);
            }, 4000);
            return true;
        }
        else if (req_data.invoice_date > shortDate) {
            SetBtnDisable(btndis = true);
            setRecrutdate(true);
            setTimeout(() => {
                setRecrutdate(false);
            }, 4000);
            return true;
        }
        else if (req_data.repair_date > req_data.invoice_date) {
            SetBtnDisable(btndis = true);
            setInvodate(true);
            setTimeout(() => {
                setInvodate(false);
            }, 4000);
            return true;
        } else {
            setDateValid(false);
            req_data.claim_id = id;
            req_data.is_file_removed = ISfile_removed;
            req_data.invoice_file = file_invoice;
            req_data.is_upload = true;
            req_data.claim_expiry_date = seSerialNo;
            req_data.partNum = serPartNum;
            req_data.status = 1;
            const curDDate = moment(new Date()).format("MM-DD-YYYY");
            req_dataSaveDraft = getValues();
            req_dataSaveDraft.web_entry_date = curDDate;
            // var response_data = await edit_claim_api(req_data);
            if (IsPartRemoved) {
                console.log('Removed some parts');
                const curDDate = moment(new Date()).format("MM-DD-YYYY");
                req_dataSaveDraft = getValues();
                req_dataSaveDraft.web_entry_date = curDDate;
                allIncPartValues.invoice_file = file_invoice;
                allIncPartValues.is_upload = true;
                allIncPartValues.is_file_removed = ISfile_removed;
                allIncPartValues.claim_expiry_date = seSerialNo;
                allIncPartValues.partNum = serPartNum;
                var response_data = await edit_claim_api(allIncPartValues);
            } else {
                var response_data = await edit_claim_api(req_data);
                console.log('Not removed parts')
            }
            if (response_data.success) {
                console.log('Response Add claim ', response_data);
                if (response_data.new_web_reference_no === '') {
                    SetSuMsg(suMSG = response_data.msg);
                    ShowSuc(!suchidden);
                    setTimeout(() => {
                        ShowSuc(suchidden = true);
                        SetBtnDisable(btndis = true);
                        return navigate("/claims");
                    }, 1000);
                } else {
                    SetsuWebMSG(true);
                    // SetNewWEbnoMSG(response_data.new_web_reference_no);
                    setTimeout(() => {
                        SetBtnDisable(btndis = true);
                    }, 2000)
                    setTimeout(() => {
                        // ShowSuc(suchidden = true);
                        setisWarrentyExpired(false);
                        // reset();
                        return navigate("/claims");
                    }, 1000);
                    // reset();
                }
            } else {
                SetErMsg(suMSG = response_data.msg);
                ShowErr(errhidden = true);
                setTimeout(() => {
                    ShowErr(errhidden = false);
                }, 15000);
                SetBtnDisable(btndis = true);
            }
            console.log("invoice_file_edit", invoice_file_edit)
        }
        // return

        // if (invoice_file_edit == null) {
        //     SetIsFileValid("file required")
        // } else {
        //     SetIsAllFieldValid(true);
        //     e.preventDefault();
        //     SetBtnDisable(btndis = false);
        //     console.log(req_data);
        //     console.log(invoice_file_edit);
        //     req_data.claim_id = id;
        //     req_data.is_file_removed = ISfile_removed;
        //     req_data.invoice_file = file_invoice;
        //     req_data.status = 1;
        //     var response_data = await edit_claim_api(req_data);
        //     if (IsPartRemoved) {
        //         console.log('Removed some parts');
        //         allIncPartValues.invoice_file = file_invoice;
        //         allIncPartValues.is_file_removed = ISfile_removed;
        //         var response_data = await edit_claim_api(allIncPartValues);
        //     } else {
        //         var response_data = await edit_claim_api(req_data);
        //         console.log('Not removed parts')
        //     }
        //     if (response_data.success) {
        //         console.log('Response Add claim ', response_data);
        //         if (response_data.new_web_reference_no === '') {
        //             SetSuMsg(suMSG = response_data.msg);
        //             ShowSuc(!suchidden);
        //             setTimeout(() => {
        //                 ShowSuc(suchidden = true);
        //                 SetBtnDisable(btndis = true);
        //                 return navigate("claims");
        //             }, 1000);
        //         } else {
        //             SetsuWebMSG(true);
        //             setTimeout(() => {
        //                 SetBtnDisable(btndis = true);
        //             }, 2000)
        //             setTimeout(() => {
        //                 setisWarrentyExpired(false);
        //                 return navigate("claims");
        //             }, 1000);
        //         }
        //     } else {
        //         SetErMsg(suMSG = response_data.msg);
        //         ShowErr(!errhidden);
        //         setTimeout(() => {
        //             ShowErr(errhidden = true);
        //         }, 3000);
        //         SetBtnDisable(btndis = true);
        //     }
        // }

    }

    const today = moment();
    const disableFutureDt = current => {
        return current.isBefore(today)
    }

    var [showlistno, setShowListno] = useState(false);
    const DatalistNo = (event) => {
        console.log("part no popup", event.target.value.length);
        if (event.target.value.length > 3) {
            setShowListno(true);
        } else {
            setShowListno(false);
        }
    }

    const DatalistNoOnFocus = () => {
        console.log("onfocus called", showlistno)
        setShowListno(false);
    }
    var comp_reset; var gate_reset; var hr_reset;

    const addRepair = () => {
        // console.log(repair_gate_id);
        SetrepairTabChange(false);
        // comp_reset = `repairs.${activeRepairIndex + 1}.component`;
        // gate_reset = `repairs.${activeRepairIndex + 1}.gate_type`;
        // hr_reset = `repairs.${activeRepairIndex + 1}.labour_hours`;
        // // SetBtnDisable(btndis=false);
        // setTimeout(() => {
        //     setValue(gate_reset, '', { shouldValidate: false });
        //     resetField(gate_reset);
        //     setValue(comp_reset, '', { shouldValidate: false });
        //     resetField(comp_reset);
        //     setValue(hr_reset, '', { shouldValidate: false });
        //     resetField(hr_reset);
        //     SetrepairComponent('');
        //     SetrepairGatetype('');
        //     SetLaborHours('');
        // }, 1500);

        // setTimeout(() => {
        //     SetBtnDisable(btndis=true);
        // }, 2300);

        var partId = repairs.length + '-0'
        repairs.push({ category: '', gate_type: '', labour_hours: '', labour_rate: '', labour_total: '', misc_charge: '', misc_description: '', repair_description: '', repair_parts_total: '', parts: [{ repairpart: partId, part_number: '', parts_description: '', quantity_claimed: '', unit_price_claimed: '', partstotal: '' }] })
        var len = repairs.length - 1;
        setActiveRepairIndex(len);
        curRepairPartList = [];
        setcurRepairPartList(...curRepairPartList, repairs[activeRepairIndex].parts);
        setActivePartIndex(0);
        setTimeout(() => {
            activeIndex(len);
        }, 100);
        SetRepairCount(repairCount + 1);
        setValue('repairs_count', repairCount + 1)
        SetPartCount(partCount + 1);
        setValue('parts', partCount + 1)
        SetTotalCount(totalCount + 2);
        setValue('total_claim', totalCount + 2)
        var rep_gate_val = "repairs." + len + ".gate_type";
        setValue(rep_gate_val, '');
        var rep_comp_val = "repairs." + len + ".component";
        setValue(rep_comp_val, '');
    };

    const addPart = (index) => {
        const repairIndex = repairs.findIndex((_, idx) => idx === index);
        const repairpart = index + "-" + (repairs[repairIndex].parts.length + 1);
        repairs[repairIndex].parts.push({ repairpart: repairpart, part_number: '', parts_description: '', quantity_claimed: 0, unit_price_claimed: 0, partstotal: 0 })
        curRepairPartList = [];
        curRepairPartList = [...curRepairPartList, ...repairs[repairIndex].parts]
        setcurRepairPartList(curRepairPartList);
        setActivePartIndex((curRepairPartList.length - 1))
        if (repairs[index].parts.length < 1) {
            // alert("Part Empty");
            SetpartCheckboxHide(partCheckboxHide = false);
        } else {
            SetpartCheckboxHide(partCheckboxHide = true);
        }
        SetPartCount(partCount + 1);
        setValue('parts', partCount + 1)
        SetTotalCount(totalCount + 1);
        setValue('total_claim', totalCount + 1)
        var rep_part_val = "repairs." + repairIndex + ".parts." + (curRepairPartList.length - 1) + ".part_number";
        setValue(rep_part_val, '');
        var rep_part_decs = "repairs." + repairIndex + ".parts." + (curRepairPartList.length - 1) + ".parts_description";
        setValue(rep_part_decs, '');
    };

    const activeIndex = (index) => {
        console.log("dsdfsd")
        setActiveRepairIndex(index);
        // curRepairPartList = [];
        // setTimeout(() => {
        //     curRepairPartList = [...curRepairPartList, ...repairs[index].parts]
        // }, 1000)
        //setcurRepairPartList(repairs[index].parts);
        curRepairPartList = [];
        curRepairPartList = [...curRepairPartList, ...repairs[index].parts]
        setcurRepairPartList(curRepairPartList);

    };

    const activeIdx = (index, event) => {
        event.preventDefault();
        setActivePartIndex(index);
    };

    const activeRIndex = (index, event) => {
        SetrepairTabChange(true);
        console.log("index", index);
        event.preventDefault();

        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        // unregister(repairs[index]);
        setActiveRepairIndex(index);
        curRepairPartList = [];
        console.log(repairs[index])
        curRepairPartList = [...curRepairPartList, ...repairs[index].parts]

        setcurRepairPartList(curRepairPartList);
        setActivePartIndex(0)
    };

    const repairRemove = (index, event) => {
        // console.log("Remove repair");
        SetrepairTabChange(true);
        var remove_part_count = repairs[index].parts.length;
        event.preventDefault();
        repairs.splice(index, 1);
        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        // unregister(repairs[index]);
        setActiveRepairIndex(repairIdx);
        curRepairPartList = [];
        curRepairPartList = [...curRepairPartList, ...repairs[repairIdx].parts]
        setcurRepairPartList(curRepairPartList);
        SetRepairCount(repairCount - 1);
        setValue('repair_count', repairCount - 1)
        SetPartCount(partCount - remove_part_count);
        setValue('parts', partCount - remove_part_count)
        SetTotalCount(totalCount - remove_part_count - 1);
        setValue('total_claim', totalCount - remove_part_count - 1)
    };

    var allIncPartValues;
    var IsPartRemoved;
    var [partCheckboxHide, SetpartCheckboxHide] = useState(true);
    const partRemove = (index, partIndex, event) => {
        IsPartRemoved = localStorage.setItem('ispartremoved', true);
        allIncPartValues = getValues();
        // console.log('IS partRemoveD', IsPartRemoved,index,partIndex);
        event.preventDefault();
        allIncPartValues.repairs[index].parts.splice(partIndex, 1);
        // console.log('After partRemove', allIncPartValues,index,partIndex);
        // return;
        repairs[index].parts.splice(partIndex, 1);
        // console.log(repairs[index].parts);
        if (repairs[index].parts.length < 1) {
            // alert("Part Empty");
            SetpartCheckboxHide(partCheckboxHide = false);
        } else {
            SetpartCheckboxHide(partCheckboxHide = true);
        }

        if (partIndex == 0) {
            var partIndex = partIndex;
        } else {
            var partIndex = partIndex - 1;
        }
        setTimeout(() => {
            activeIndex(index);
            setActivePartIndex(partIndex)
        }, 100);
        var curRepairPartList = [];
        curRepairPartList = [...curRepairPartList, ...repairs[index].parts]
        setcurRepairPartList(curRepairPartList);
        // setValue('repairs', allIncPartValues.repairs);
        curRepairPartList.forEach((element, pidx) => {
            var part_val = "repairs." + index + ".parts." + pidx;
            setValue(part_val, allIncPartValues.repairs[index].parts[pidx]);
        });

        SetPartCount(partCount - 1);
        setValue('parts', partCount - 1)
        SetTotalCount(totalCount - 1);
        setValue('total_claim', totalCount - 1)
    };

    var [showERRfields, SetshowERRfields] = useState(false);
    var [labourHour, setabourHour] = useState();

    const OnError = (error) => {
        // console.log(invoice_file_edit);
        // if (error.repairs && error.repairs[activeRepairIndex].labour_hours?.type == 'required') {
        //     setabourHour("Labour hour is required")
        //     setTimeout(() => {
        //         setabourHour("")
        //     }, 6000);
        //     SetBtnDisable(true)
        //     return
        // }
        var fvalue = control._formValues.repairs;
        fvalue.forEach((val, index) => {
            if (val.gate_type != "" && val.gate_type != null && val.gate_type != undefined && val.gate_type != 'undefined' && val.category != '') {
                if (control._formState.errors.repairs && control._formState.errors.repairs[index] && control._formState.errors.repairs[index].gate_type && control._formState.errors.repairs[index].gate_type.message) {
                    control._formState.errors.repairs[index].gate_type.message = '';
                }
            }
            if (val.component != "" && val.component != null && val.component != undefined && val.component != 'undefined' && val.category != '') {
                if (control._formState.errors.repairs && control._formState.errors.repairs[index] && control._formState.errors.repairs[index].component && control._formState.errors.repairs[index].component.message) {
                    control._formState.errors.repairs[index].component.message = '';
                }
            }
        })
        // if (invoice_file_edit == null) {
        //     SetIsFileValid("Invoice file is required")
        // }
        console.log('Errors', error);
        SetshowERRfields(true);
        setTimeout(() => {
            SetshowERRfields(false);
        }, 3000);
    }

    const Misc_charge_not_Empty = (event, index) => {
        var miscid = "mDesc" + index;
        // var repid = "rDesc" + index
        if (event.target.value.length > 0) {
            document.getElementById(miscid).disabled = false;
        } else {
            document.getElementById(miscid).disabled = true;
            var Repair_Mdesc = "repairs." + activeRepairIndex + ".misc_description"
            // setValue(Repair_Mdesc, '');
        }
        // if (event.target.value.length > 0) {
        //     document.getElementById(repid).disabled = false;
        // } else {
        //     document.getElementById(repid).disabled = true;
        //     var Repair_Rdesc = "repairs." + activeRepairIndex + ".repair_Description"
        //     // setValue(Repair_Rdesc, '');
        // }
        // console.log(event.target.value);
        if (event.target.value.length > event.target.maxLength) {
            event.target.value = event.target.value.slice(0, event.target.maxLength)
        }
        if (event.target.value !== '') {
            SetIsMiscFieldValid(true);
        } else {
            SetIsMiscFieldValid(false);
        }
    }
    function cancelpop(event) {
        event.preventDefault();
        setShow(!show)
    }
    function staysamepage() {
        setShow(!show)
    }
    function claimerrokay() {
        setPopShowerr(!popshowerr)
    }
    function backtoclaims() {
        localStorage.setItem('fromdetails', 'pagecount')
        return navigate('/claims');
    }
    return (
        <div className="addclaim-container">
            <LEFTMENU />
            {/* <div className="menuall">
                <div className="lmenu">
                    <LEFTMENU />
                </div>
                <div className="tmenu">
                    <Topmenu />
                </div>
            </div> */}
            <div className="container details_All">
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                {isWarrentyExpired && <small className='Warrenty Popup'>
                    Your warranty is expired..
                </small>}
                {showERRfields && <small className='Errmsg Warrenty Popup'>** Please fill the all repair tab fields</small>
                }{
                    recrutdate && <small className='Errmsg Warrenty Popup'>*Ensure that the invoice date should not after current date</small>
                }
                {
                    crutdate && <small className='Errmsg Warrenty Popup'>*Ensure that the repair date should not after current date</small>
                }
                {
                    invodate && <small className='Errmsg Warrenty Popup'>*Ensure that the invoice date is after the repair date</small>
                }
                {
                    dateValid && <small className='Errmsg Warrenty Popup'>*Ensure that the repair date is after the purchase date</small>
                }
                <div className="claim_top_bar">
                    <div className='toaster suc draft' hidden={suchiddenDraft}>
                        <h2>Draft added successfully</h2>
                    </div>
                    <div className='toaster suc clmtoster' hidden={suchidden}>
                        <h2>{suMSG}</h2>
                    </div>
                    {errhidden && <div className='toaster fail mdesc'>
                        <h2>{erMSG}</h2>
                    </div>}
                    {suWebMSG && <div className='toaster suc pop'>
                        <h2>Claim added successfully. {`Your Web ref no is`} <span className='RefNo'>{claimDetails[0].web_reference_no}</span></h2>
                    </div>}
                    {
                        redirectpop && <div className="CanPop">
                            <div className="can_popTotal">
                                <div className='can_title'>
                                    Notice
                                </div>
                                <hr />
                                <div className='cancel_content'>
                                    <p>
                                        Claim Already Placed.
                                    </p>
                                </div>
                                <hr />
                                <div className='cancel_btns'>
                                    <button className='can_yes' onClick={gotoview}>Ok</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className=" user_topbar">
                        <div className="">
                            <h3>Edit Claim</h3>
                        </div>
                        <div className="">
                            {/* <div className="control_search"> */}
                            <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <div className="logImg"><img src={newlogout}></img></div>
                                <div className="warrntyp" >
                                    <p>LOG OUT</p>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="previous_icon row">
                    <div className="col-md-2" onClick={cancelpop}>
                        <img src={backnew} />
                        <span> Back to Claims</span>
                    </div>
                </div>
                {
                    popshowerr && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Error Alert
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                {showError.map((errmsg, index) => (
                                    <ul>
                                        <li className='clmerrmsg'>{errmsg}</li>
                                    </ul>
                                ))}
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_no' onClick={claimerrokay}>OK</button>
                            </div>
                        </div>
                    </div>
                }
                <div>
                    {
                        show && <div className="CanPop">
                            <div className="can_popTotal">
                                <div className='can_title'>
                                    Confirmation
                                </div>
                                <hr />
                                <div className='cancel_content'>
                                    <p>
                                        By cancelling, any data you have provided will not be saved.
                                    </p>
                                </div>
                                <hr />
                                <div className='cancel_btns'>
                                    <button className='can_no' onClick={staysamepage}>Cancel</button>
                                    <button className='can_yes' onClick={backtoclaims}>Ok</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="card container add_card_total" >
                    <div className="card-body">

                        <form className="multiform">
                            <div className="basic-info-fields">
                                <div className="subName">
                                    <p>Basic Information</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 lable_cs">
                                        <label>Web Reference Number</label>
                                        <input readOnly type="text" className="form-control"
                                            placeholder="Enter the Web Ref No." defaultValue={claimDetails[0].web_reference_no}
                                        />
                                    </div>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-3 lable_cs webdate">
                                        <label>Web Entry Date*</label>
                                        <input type="date" readOnly className="form-control" defaultValue={getCurrentDateInput()} placeholder="Enter the Web Entry Date" name='web_entry_date' {...register("web_entry_date", {
                                            required: true,
                                        })} />
                                        {errors.web_entry_date?.type === "required" && (
                                            <small className='errMsg'>Web Entry Date is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Serial Number*</label>
                                        <input type="text" className="form-control"
                                            id="serialnothis"
                                            onKeyDown={MinusValueCheck} placeholder="Enter the Serial Number" name='serial_no' {...register("serial_no", {
                                                required: IsAllFieldValid,
                                            })} />
                                        {isSeValidMsg ? <small className="errMsg">Please enter valid Serial No<br /></small> : ''}

                                        {IsAllFieldValid && errors.serial_no?.type === "required" && (
                                            <small className='errMsg'>Serial No is required</small>
                                        )}
                                    </div>

                                    <div className="col-md-3 lable_cs">
                                        <label>Repair Date*</label>
                                        <input type="date" className="form-control" value={title}
                                            max={moment().format("YYYY-MM-DD")} placeholder="Enter the Repair Date" name='repair_date' {...register("repair_date", {
                                                required: IsAllFieldValid,
                                            })} />
                                        {IsAllFieldValid && errors.repair_date?.type === "required" && (
                                            <small className='errMsg'>Repair Date is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Invoice No.*</label>
                                        <input type="text" maxLength={30} onInput={numValidate} className="form-control" placeholder="Enter the Invoice No." name='invoice_no' {...register("invoice_no", {
                                            required: IsAllFieldValid,
                                            maxLength: 30
                                        })} />
                                        {IsAllFieldValid && errors.invoice_no?.type === "required" && (
                                            <small className='errMsg'>Invoice No is required</small>
                                        )}
                                        {IsAllFieldValid && errors.invoice_no?.type === "maxLength" && (
                                            <small className='errMsg'>Max length exceeded</small>
                                        )}
                                    </div>

                                    <div className="col-md-6 lable_cs">
                                        <label>Cycle Count</label>
                                        <input type="text" maxLength={10}
                                            onKeyPress={(event) => {
                                                if (!/[0-9 .]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} className="form-control" onInput={numValidate} onKeyDown={MinusValueCheck} min="0" placeholder="Enter the Cycle Count" {...register("cycle_count", { required: false })} />
                                        {errors.cycle_count?.type === "required" && (
                                            <small className='errMsg'>Cycle Count is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 lable_cs">
                                        <label>Invoice Date*</label>
                                        <input type="date" className="form-control" placeholder="Enter the Invoice Date" name='invoice_date' max={moment().format("YYYY-MM-DD")} min={title} {...register("invoice_date", {
                                            required: IsAllFieldValid,
                                        })} />
                                        {IsAllFieldValid && errors.invoice_date?.type === "required" && (
                                            <small className='errMsg'>Invoice Date is required</small>
                                        )}
                                    </div>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6 lable_cs">
                                        <label>Part Description</label>
                                        <input readOnly type="text" className="form-control" placeholder="" value={serialNopartdesc}
                                            {...register("model_no", {
                                                required: IsAllFieldValid,
                                            })}
                                        />
                                        {/* {IsAllFieldValid && errors.model_no?.type === "required" && (
                                            <small className='errMsg'>Model No is required</small>
                                        )} */}
                                    </div>
                                </div>
                                <hr />
                                <div className="subName">
                                    <p>End User Information</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Company Name</label>
                                        <input type="text" className="form-control" readOnly
                                            value={serialNocompanyname}
                                            placeholder="" name='company_name' {...register("company_name")} />
                                        {/* {IsAllFieldValid && errors.company_name?.type === "required" && (
                                            <small className='errMsg'>Company Name is required</small>
                                        )} */}
                                    </div>
                                    <div className="col-md-3 lable_cs purchase_date">
                                        <label>Purchase Date</label>
                                        <input type="date" readOnly className="form-control"
                                            value={serialNopurchaseDate}
                                            placeholder="Enter the Purchase Date" name='purchase_date' {...register("purchase_date", {
                                                required: IsAllFieldValid,
                                            })} />
                                        {/* {IsAllFieldValid && errors.purchase_date?.type === "required" && (
                                            <small className='errMsg'>Purchase Date is required</small>
                                        )} */}
                                    </div>
                                </div>

                                <hr />
                                <div className="subName">
                                    <p>Summary</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Parts</label>
                                        <input readOnly type="text" className="form-control" placeholder="Enter the Parts" value={partCount} {...register("parts", {
                                            required: false,
                                        })} />
                                        {errors.parts?.type === "required" && (
                                            <small className='errMsg'>Parts is required</small>
                                        )}
                                    </div>
                                    <div className="col-md-6 lable_cs">
                                        <label>Repairs</label>
                                        <input readOnly type="text" className="form-control" placeholder="Enter the Repairs" value={repairCount}  {...register("repairs_count", {
                                            required: false,
                                        })} />
                                        {errors.repairs?.type === "required" && (
                                            <small className='errMsg'>Repairs is required</small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 lable_cs">
                                        <label>Total Claim</label>
                                        <input readOnly type="text" className="form-control" placeholder="Enter the Total Claim" name='total_claim'
                                            value={totalCount}
                                            {...register("total_claim", {
                                                required: false,
                                            })} />
                                        {errors.total_claim?.type === "required" && (
                                            <small className='errMsg'>Total Claim is required</small>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <label>Upload Invoice</label>
                                        <div className='invo_file'>
                                            <div className='file_input'>
                                                <input type="file" className="form-control"
                                                    accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.PDF"
                                                    {...register("invoice_file",
                                                    )} />
                                                <p className='file_format'>*Formats Accepted jpg,jpeg,png,pdf. Max file size 15MB</p>
                                            </div>
                                            {(!ISfile_removed && file_invoiceremove && invoice_file_edit !== null &&
                                                <div className="remove_file">
                                                    <a href={invoice_file_edit} target='_blank' className="LinkInvoice">Invoice </a>
                                                    <span className="removeInvoice" onClick={InvoiceFileRemove}>x</span>
                                                </div>)}
                                        </div>
                                        {fileerr && <small className='errMsg'>*Invalid file format</small>}
                                        {fileerrsize && <small className='errMsg'>*File size exceeds the allowed limit of 15MB</small>}
                                        {/* {errors.invoice_file?.type === "required" && (
                                            <small className='errMsg'>Invoice file is required</small>
                                        )}
                                        {isFileValid} */}
                                    </div>
                                    {/* <div className="col-md-2">
                                        <label></label>
                                        {(!ISfile_removed && file_invoiceremove && invoice_file_edit !== null &&
                                            <div className="remove_file">
                                                <a href={invoice_file_edit} target='_blank' className="LinkInvoice">Invoice File </a>
                                                <span className="removeInvoice" onClick={InvoiceFileRemove}>x</span>
                                            </div>)}
                                    </div> */}

                                </div>
                            </div>

                            <div className="Repairs_fields">
                                <div className="container">
                                    {/* <div> */}
                                    <h4>Add New Repair</h4>
                                    <div className="repair-tabs">
                                        {repairs.map((repair, index) => (
                                            <React.Fragment key={index + 'sS'}>
                                                <div className="repair-intabs tabsspace">
                                                    <button

                                                        className={index === activeRepairIndex ? 'active' : ''}
                                                        onClick={(event) => activeRIndex(index, event)}
                                                    >
                                                        Repair {index + 1}
                                                    </button>
                                                    {repairs.length > 1 && (
                                                        <button key={index + 'a'}
                                                            className='repair-tabss close active' onClick={(event) => { repairRemove(index, event); removeRepair(index) }}>
                                                            X
                                                        </button>)}
                                                </div>
                                            </React.Fragment>

                                        ))}

                                        <button type="button" className='addrepairbtn' onClick={addRepair}>
                                            +
                                        </button>
                                    </div>

                                    <div className="repair-details">

                                        {repairs.map((repair, index) => (
                                            <div key={index + 'g'} className={index === activeRepairIndex ? 'active' : 'inactive'}>
                                                {(
                                                    <>
                                                        {/* <hr /> */}
                                                        <div className="row">
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Category*</label>
                                                                <div className="custom-select">
                                                                    <select className="form-select" aria-label="Default select example" {...register(`repairs.${index}.category`, {
                                                                        required:
                                                                            { value: IsAllFieldValid, message: 'Category is required' }
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        {category_list.map((catelist) => {
                                                                            return (
                                                                                <option value={catelist.id} key={catelist.id}>{catelist.category}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.category && <small className='errMsg'>{errors?.repairs?.[index]?.category.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Gate Type*</label>
                                                                <div className="custom-select">
                                                                    <select value={repairGatetype} defaultValue={repairs[index].gate_type_id} className="form-select" aria-label="Default select example" {...register(`repairs.${index}.gate_type`, {
                                                                        required:
                                                                            { value: IsAllFieldValid, message: 'Gate type is required' }
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        {gate_type_list && gate_type_list.map((gatelist) => {
                                                                            return (
                                                                                <option key={gatelist.id} value={gatelist.id}>{gatelist.gate_type}</option>
                                                                            )
                                                                        })}

                                                                    </select>
                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.gate_type && <small className='errMsg'>{errors?.repairs?.[index]?.gate_type.message}</small>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 lable_cs">
                                                                <label>Component*</label>
                                                                <div className="custom-select">
                                                                    <select value={repairComponent} className="form-select" aria-label="Default select example" {...register(`repairs.${index}.component`, {
                                                                        required:
                                                                            { value: IsAllFieldValid, message: 'Component is required' }
                                                                    })}>
                                                                        <option value=''>Select</option>

                                                                        {component && component.map((componentlist) => {
                                                                            return (
                                                                                <option key={componentlist.id} value={componentlist.id}>{componentlist.component}</option>
                                                                            )
                                                                        })}
                                                                    </select>

                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.component && <small className='errMsg'>{errors?.repairs?.[index]?.component.message}</small>}
                                                                </div>
                                                            </div>

                                                            <hr />
                                                            <div className="subName">
                                                                <p>Labor</p>
                                                            </div>

                                                            <div className="col-md-6 lable_cs">
                                                                <label>Hours*</label>
                                                                <input type="number" pattern="[0-9]{10}"
                                                                    className="form-control"
                                                                    onWheel={(e) => e.target.blur()}
                                                                    //   value={laborhours}
                                                                    inputMode="decimal" maxLength={10} onInput={numValidate}
                                                                    onKeyDown={MinusValueCheck}
                                                                    placeholder="Enter the Hours" {...register(`repairs.${index}.labour_hours`, {
                                                                        required:
                                                                        {
                                                                            value: IsAllFieldValid, message: 'Hours is required'
                                                                        }
                                                                    })} />
                                                                {/* {isHourChangedMsg && <small className='errMsg'>You are changing actual hours</small>} */}
                                                                {/* <small className='errMsg'> {labourHour}</small> */}
                                                                {IsAllFieldValid && errors?.repairs?.[index]?.labour_hours && <small className='errMsg'>{errors?.repairs?.[index]?.labour_hours.message}</small>}

                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Rate*</label>
                                                                <div className="dollarsign">
                                                                    <input type="number" maxLength={10} onInput={numValidate}
                                                                        onWheel={(e) => e.target.blur()}
                                                                        inputMode="decimal"
                                                                        className="form-control"
                                                                        onKeyDown={MinusValueCheck} min='0' placeholder="Enter the Rate" {...register(`repairs.${index}.labour_rate`, {
                                                                            required:
                                                                                { value: IsAllFieldValid, message: 'Labor Rate is required' }, min: { value: 1, message: 'Please add greater than 0' }
                                                                        })} />
                                                                    {IsAllFieldValid && errors?.repairs?.[index]?.labour_rate && <small className='errMsg'>{errors?.repairs?.[index]?.labour_rate.message}</small>}
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Repair Description*</label>
                                                                <textarea type="text" className="form-control" placeholder="Enter the Repair Description" {...register(`repairs.${index}.repair_Description`, {
                                                                    required:
                                                                        { value: IsAllFieldValid, message: 'Repair Description is required' }
                                                                })} ></textarea>
                                                                {errors?.repairs?.[index]?.repair_Description && <small className='errMsg'>{errors?.repairs?.[index]?.repair_Description.message}</small>}
                                                            </div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Total</label>
                                                                <div className="dollarsign">
                                                                    <input type="number"
                                                                        readOnly
                                                                        defaultValue={repairs[index].labour_total}
                                                                        onKeyDown={MinusValueCheck} className="form-control" placeholder="Enter the Total" {...register(`repairs.${index}.labour_total`)} />
                                                                    {errors?.repairs?.[index]?.labour_total && <small className='errMsg'>{errors?.repairs?.[index]?.labour_total.message}</small>}
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Misc charge</label>
                                                                <div className="dollarsign">
                                                                    <input type="number" maxLength={10} min='0' defaultV onInput={(event) => Misc_charge_not_Empty(event, index)} onKeyPress={(event) => {
                                                                        if (!/[0-9 .]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                        onWheel={(e) => e.target.blur()} className="form-control" onKeyDown={MinusValueCheck} placeholder="Enter the Misc Charge" {...register(`repairs.${index}.misc_charge`, {
                                                                            required:
                                                                                { value: false, message: 'Misc charge is required' }, min: { value: 1, message: 'Please add greater than 0.' }
                                                                        })} />
                                                                    {errors?.repairs?.[index]?.misc_charge && <small className='errMsg'>{errors?.repairs?.[index]?.misc_charge.message}</small>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 lable_cs"></div>
                                                            <div className="col-md-6 lable_cs">
                                                                <label>Misc Description</label>
                                                                <textarea type="text" id={'mDesc' + index} disabled="disabled" className="form-control" placeholder="Enter the Misc Description" {...register(`repairs.${index}.misc_description`)} ></textarea>
                                                                {errors?.repairs?.[index]?.misc_description && <small className='errMsg'>{errors?.repairs?.[index]?.misc_description.message}</small>}

                                                            </div>

                                                            <div className="col-md-6 lable_cs">
                                                                <label>Repair Total</label>
                                                                <div className="dollarsign">
                                                                    <input type="number" className="form-control" readOnly  {...register(`repairs.${index}.repair_parts_total`, {
                                                                        required:
                                                                            { value: false, message: 'Repair Parts Total is required' }
                                                                    })}
                                                                    />
                                                                    {errors?.repairs?.[index]?.repair_parts_total && <small className='errMsg'>{errors?.repairs?.[index]?.repair_parts_total.message}</small>}
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <h4>Add New Part</h4>
                                                            <div className="part-tabs-border">
                                                                <div className="part-tabs clmparts">
                                                                    {curRepairPartList && curRepairPartList.map((part, partIndex) => (
                                                                        <button
                                                                            key={index + ' ' + partIndex}
                                                                            className={partIndex === activePartIndex ? 'active' : ''} aria-selected="true"
                                                                            onClick={(event) => activeIdx(partIndex, event)}
                                                                        >
                                                                            Part {partIndex + 1}
                                                                            <span className='close' onClick={(event) => { partRemove(index, partIndex, event); }}>X</span>
                                                                        </button>
                                                                    ))}
                                                                    <button type="button" className='addpartbtn' onClick={() => addPart(index)}>
                                                                        +
                                                                    </button>
                                                                </div>
                                                                <div className="part-details">
                                                                    {(partCheckboxHide && <div className="row">
                                                                        <div className="col-md-6 lable_cs">
                                                                            <div className="known-opt" >
                                                                                <label htmlFor="">Please select which one you know.</label>
                                                                                <div className="known-optin-top">
                                                                                    <div className="known-optin partno" data-val={checked}>
                                                                                        <label htmlFor="Partno">
                                                                                            <input type="radio" id="Partno" {...register('known_option')} value="Part No" data-val={checked} />
                                                                                            Part Number</label>
                                                                                    </div>
                                                                                    <div className="known-optin partdesc" data-val={checked}>
                                                                                        <label htmlFor="PartDes">
                                                                                            <input type="radio" id="PartDes" {...register('known_option')} value="Part Description" data-val={checked} />
                                                                                            Part Description</label><br />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                                    {curRepairPartList && curRepairPartList.map((part, partIndex) => (
                                                                        <div key={partIndex + 'c'} className={partIndex === activePartIndex ? 'active' : 'inactive'}>
                                                                            {(
                                                                                <>

                                                                                    {known_optionVal === "Part No" && <div className="row">
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Part Number</label>

                                                                                            <input list="partlists" autoComplete="off" name="partlist" id="partlist" className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} />

                                                                                            <datalist id="partlists">

                                                                                                {Part_NoList && Part_NoList.map((part) => (
                                                                                                    <option key={part.id} value={part.PartNum} />
                                                                                                ))}

                                                                                            </datalist>


                                                                                            {/* <input type="text" className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} /> */}
                                                                                            {errors?.repairs?.[index]?.[partIndex]?.part_number && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.part_number.message}</small>}
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Part Description</label>
                                                                                            {/* <input list="partDescs" value={PART_DESC} name="partDesc" id="partDesc" className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} />
                                                                                            <datalist id="partDescs">
                                                                                                {partLists.map((part) => (
                                                                                                    <option key={part.id} value={part.PartDescription} />
                                                                                                ))}
                                                                                            </datalist> */}
                                                                                            <input readOnly={known_optionVal === "Part No"} type="text" defaultValue={PART_DESC} className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} />
                                                                                            {errors?.repairs?.[index]?.[partIndex]?.parts_description && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.parts_description.message}</small>}
                                                                                        </div>
                                                                                    </div>}

                                                                                    {known_optionVal === "Part Description" && <div className="row">
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <div className="partnoarea">
                                                                                                <label>Part Number</label>
                                                                                                {/* <input list="partlists" name="partlist" id="partlist" className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} /> */}
                                                                                                {/* <datalist id="partlists" >
                                                                                                {partLists.map((part) => (
                                                                                                    <option key={part.id} value={part.PartNum} />
                                                                                                ))}


                                                                                            </datalist> */}
                                                                                                {/* <span class='partno-append'>{PART_NUMBER}</span> */}
                                                                                                <input type="text" readOnly={known_optionVal === "Part Description"} value={PART_NUMBER} className="form-control" placeholder="Enter the Part Number " {...register(`repairs.${index}.parts.${partIndex}.part_number`)} />
                                                                                                {errors?.repairs?.[index]?.[partIndex]?.part_number && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.part_number.message}</small>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Part Description</label>
                                                                                            <input type='text' list="partDescs" name="partDesc" id="partDesc" className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} />
                                                                                            <datalist id="partDescs">
                                                                                                {partLists.map((part) => (
                                                                                                    <option key={part.id} value={part.description} />
                                                                                                ))}


                                                                                            </datalist>
                                                                                            {/* <input type="text" value={PART_DESC} className="form-control" placeholder="Enter the Part Description " {...register(`repairs.${index}.parts.${partIndex}.parts_description`)} /> */}
                                                                                            {errors?.repairs?.[index]?.[partIndex]?.parts_description && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.parts_description.message}</small>}
                                                                                        </div>
                                                                                    </div>}
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Quantity Claimed</label>
                                                                                            <input type="text" maxLength={10} onKeyPress={(event) => {
                                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }} onInput={numValidate} onKeyDown={MinusValueCheck} min='0' className="form-control" placeholder="Enter the Quantity Claimed" {...register(`repairs.${index}.parts.${partIndex}.quantity_claimed`)} />
                                                                                            {errors?.repair?.[index]?.[partIndex]?.quantity_claimed && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.quantity_claimed.message}</small>}
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Unit Price Claimed</label>
                                                                                            <div className="dollarsign">
                                                                                                <input type="number" maxLength={10} onWheel={(e) => e.target.blur()} onInput={numValidate} onKeyDown={MinusValueCheck} min='0' className="form-control" placeholder="Enter the Unit Price Claimed " {...register(`repairs.${index}.parts.${partIndex}.unit_price_claimed`)} />
                                                                                                {errors?.repair?.[index]?.[partIndex]?.unit_price_claimed && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.unit_price_claimed.message}</small>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 lable_cs">
                                                                                            <label>Total</label>
                                                                                            <div className="dollarsign">
                                                                                                <input type="number" readOnly defaultValue={QuantityunitTotalClaim} className="form-control" placeholder="Enter the Total" {...register(`repairs.${index}.parts.${partIndex}.partstotal`)} />
                                                                                                {errors?.repair?.[index]?.[partIndex]?.partstotal && <small className='errMsg'>{errors?.repair?.[index]?.[partIndex]?.partstotal.message}</small>}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <hr />
                                <div className="btn-group addclaim">
                                    {/* <Link to={'/claims'}> */}
                                    <button className="cancal-sub-btn" onClick={cancelpop}>
                                        <img src={CanCelBtn} alt='cancel-icon' /> Cancel </button>
                                    {/* </Link> */}
                                    <button className="savedraft-btn" onClick={saveDraft}>
                                        <img src={DraftBTNIcon} alt='draft-icon' /> Save Draft </button>
                                    <button type="submit" className="addclaim-sub-btn" onClick={handleSubmit(onSubmit, OnError)}>
                                        <img src={SubmitBTNIcon} alt='submit-icon' /> Submit </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
                <div className="home_footer claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser" >
                            <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default EditCheck;