import React from "react";
import '../pages/claimDetails.css';
import LEFTMENU from "./leftMenu.js";
import { useEffect, useRef } from "react";
import supabase from "../api/supabaseconnect.js";
import { Link, useNavigate } from 'react-router-dom';
import { claim_details_api } from "../api/claim_details_api.js";
import { useState } from "react";
import { useParams } from "react-router-dom";
import GIFLOADER from '../assets/loader.gif';
import moment from "moment";
import Topmenu from "./topmenu.js";
import backnew from "../assets/backnew.svg"
import file from "../assets/file.png";
import newlogout from '../assets/logoutblack.svg';

const paragraphStyles = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box'
}

function ClaimDetails() {

    const { id } = useParams()
    const [session, setSession] = React.useState(null)
    const [claimDetails, SetClaimDetails] = useState([{}]);
    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);
    var [btndis, SetBtnDisable] = useState(false);
    var [repairList, setrepairList] = useState([{}])
    var [partList, setpartList] = useState([{}])
    const [isReadMore, setIsReadMore] = useState(true);
    const [repairReadMore, setRepairReadMore] = useState(true);
    var [invoice_file_edit, Setinvoice_file_edit] = useState(null);
    const [isOpen, setIsopen] = useState(false);
    const [showReadMoreButton, setshowReadMoreButton] = useState(false);
    const ref = useRef(null);
    const [isRepairOpen, setIsRepairOpen] = useState(false);
    const [showReadMoreRepair, setshowReadMoreRepair] = useState(false);
    const [web_date, setWeb_date] = useState();
    const [repaDate, setRepaDate] = useState();
    const [purDate, setPurDate] = useState();
    const [invoDate, setinvoDate] = useState();
    const [activeyear, setActiveYear] = useState();

    const reff = useRef(null);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    const toggleRepReadMore = () => {
        setRepairReadMore(!repairReadMore)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            if (ref.current) {
                // console.log(ref.current.scrollHeight, ref.current.clientHeight)
                setshowReadMoreButton(
                    ref.current.scrollHeight !== ref.current.clientHeight)
            }
            if (reff.current) {
                setshowReadMoreRepair(
                    reff.current.scrollHeight !== reff.current.clientHeight)
            }
        }, 2000);

        // window.history.forward();
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
            // console.log(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        var response_data = claim_details_api(id).then(res => {
            console.log(res.data[0]);
            const dateObj = new Date();
            const year = dateObj.getFullYear();
            setActiveYear(year)
            SetClaimDetails(res.data);
            setWeb_date(moment(res.data[0].web_entry_date).format("MM-DD-YYYY"))
            setRepaDate(moment(res.data[0].repair_date).format("MM-DD-YYYY"))
            setPurDate(moment(res.data[0].purchase_date).format("MM-DD-YYYY"))
            setinvoDate(moment(res.data[0].invoice_date).format("MM-DD-YYYY"))
            setrepairList(res.data[0]['repair_list'])
            setpartList(res.data[0]['parts_list'])
            SetBtnDisable(true);
            if (res.data[0].invoice_file != null) {
                Setinvoice_file_edit(res.data[0].invoice_file.signedUrl);
            }

        })
        return () => subscription.unsubscribe()
    }, [])

    function backac() {
        localStorage.setItem('fromdetails', 'pagecount')
        localStorage.setItem('clmfilter', 'filtercount')

    }

    return (
        <div className="details_Total">
            <LEFTMENU />

            {/* <div className="menuall">
                <div className="lmenu">
                    <LEFTMENU />
                </div>
                <div className="tmenu">
                    <Topmenu />
                </div>
            </div> */}
            <div className="container details_All">
                <div className="claim_top_bar clmdtllog">
                    <h3>  Claims</h3>
                    <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <div className="logImg"><img src={newlogout}></img></div>
                        <div className="warrntyp" >
                            <p>LOG OUT</p>
                        </div>
                    </div>
                </div>
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                <div className="previous_icon" onClick={backac}>
                    <Link to={"/claims"}>
                        <img src={backnew} />
                        <span>  Back to Claims</span>
                    </Link>
                </div>
                <div className="claimView">
                    <p>Claim view</p>
                </div>
                <div className="card baic_info_details">
                    <div className="card-body">
                        <div className="basic_info">
                            <div className="subName">
                                <p>Basic Information</p>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Maxon Account</label>
                                    <p> {claimDetails[0]['maxon_account_no']} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Web Reference Number</label>
                                    <p> {claimDetails[0]['web_reference_no']} </p>
                                </div>

                                <div className="col-md-4">
                                    <label>Web Entry Date</label>
                                    <p>  {web_date}</p>
                                </div>
                                <div className="col-md-4">
                                    <label>Serial Number</label>
                                    <p> {claimDetails[0]['serial_no']} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Model Number</label>
                                    <p> {claimDetails[0]['model_no']} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Cycle Count</label> <br />
                                    {(() => {
                                        if (claimDetails[0]['cycle_count'] === '') {
                                            return '-'
                                                ;
                                        } else {
                                            return <p> {claimDetails[0]['cycle_count']} </p>
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Invoice Date</label>
                                    <p>{invoDate}</p>
                                </div>
                                <div className="col-md-4">
                                    <label>Repair Date.</label>
                                    <p>{repaDate}</p>
                                </div>
                                <div className="col-md-4">
                                    <label>Invoice No.</label>
                                    <p> {claimDetails[0]['invoice_no']} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Invoice File</label>
                                    {(() => {
                                        if (invoice_file_edit == null) {
                                            return <p >-</p>;
                                        } else {
                                            return <div className="remove_file">
                                                <p> <a href={invoice_file_edit} target="_blank" className=""> <img src={file} /> Invoice </a></p>
                                            </div>
                                        }
                                    })()}
                                </div>

                                <div className="col-md-4">
                                    <label>Credit memo #</label><br />
                                    {(() => {
                                        // if (claimDetails[0]['status_value'] == 'Closed') {
                                        if (claimDetails[0]['credit_memo_no'] == '' || claimDetails[0]['credit_memo_no'] == null || claimDetails[0]['credit_memo_no'] == undefined) {
                                            return '-';
                                        } else {
                                            return <p> {claimDetails[0]['credit_memo_no']} </p>
                                        }
                                        // }
                                    })()}
                                </div>

                                <div className="col-md-4">
                                    <label>Check number</label><br />
                                    {(() => {
                                        // if (claimDetails[0]['status_value'] == 'Closed') {

                                        if (claimDetails[0]['check_no'] == null || claimDetails[0]['check_no'] == '' || claimDetails[0]['check_no'] == undefined) {
                                            return '-';
                                        } else {
                                            return <p> {claimDetails[0]['check_no']} </p>
                                        }
                                        // }
                                    })()}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md">
                                    <label>Status</label>
                                    {(() => {
                                        if (claimDetails[0].check_no != '' && claimDetails[0].check_no != undefined && claimDetails[0].check_no != null && (claimDetails[0].status_value === "Closed" || claimDetails[0].status_value === "Payment Processed")) {
                                            return <div className="col-status">Payment Processed
                                                <span class="">
                                                    <br />
                                                    Maxon AP department has processed your check. See claim view for check information.</span></div>
                                        } else if (claimDetails[0].credit_memo_no != '' && claimDetails[0].credit_memo_no != undefined && claimDetails[0].credit_memo_no != null && (claimDetails[0].status_value === "Closed" || claimDetails[0].status_value === "Credit Memo Processed")) {
                                            return <div className="col-status">Credit Memo Processed
                                                <span class="">
                                                    <br />
                                                    Maxon credit department has created your credit memo. See Claim view for credit memo number.</span></div>
                                        } else if (claimDetails[0]['status_value'] == 'Submitted') {
                                            return <div className="col-status">Submitted
                                                <span class="">
                                                    <br />
                                                    Your claim was submitted as is now under review awaiting further processing.</span></div>
                                        } else if (claimDetails[0]['status_value'] == 'Processing Check') {
                                            return <div className="status-process">Processing Check
                                                <span class="">
                                                    <br />
                                                    Maxon AP department is processing your check.</span></div>
                                        } else if (claimDetails[0]['status_value'] == "Processing Credit Memo") {
                                            return <div className="status-process">Processing Credit Memo
                                                <span class="">
                                                    <br />
                                                    Maxon credit department has your claims and is working on your credit memo.</span></div>
                                        } else if (claimDetails[0]['status_value'] == "Rejected") {
                                            return <div className="status-rejected">Rejected</div>
                                        } else {
                                            return <p> {claimDetails[0]['status_value']} </p>
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                        <div className="basic_info">
                            <div className="subName">
                                <p>End User Information</p>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Company Name</label>
                                    <p> {claimDetails[0]['company_name']} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Purchase Date</label>
                                    <p>{purDate}</p>
                                </div>
                            </div>
                        </div>
                        <div className="basic_info">
                            <div className="subName">
                                <p>Summary</p>
                            </div>
                            <div className="row">
                                <div className="col-md-3 summ">
                                    <label>Parts</label><br />
                                    <p>Qty: <span> {claimDetails[0]['no_of_parts']}</span> </p>
                                    <p>Amount:<span> ${claimDetails[0]['total_parts_amount']}</span></p>
                                </div>
                                <div className="col-md-3 summ">
                                    <label>Repairs</label><br />
                                    <p>Qty: <span>  {claimDetails[0]['no_of_repairs']}</span>  </p>
                                    <p>Amount: <span> ${claimDetails[0]['total_repair_amount']}</span></p>
                                </div>
                                <div className="col-md-3 summ">
                                    <label>Misc</label><br />
                                    <div className="msc_mob">
                                        <p></p><br />
                                    </div>
                                    <p>Amount: <span> ${claimDetails[0]['total_misc_charge']}</span></p>
                                </div>
                                <div className="col-md-3 summ">
                                    <label>Total Claim</label><br />
                                    <div className="msc_mob">
                                        <p></p><br />
                                    </div>
                                    <p> <span> ${claimDetails[0]['total_claim_amount']}</span></p>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-4">
                                    <label>Parts</label>
                                    <p> {claimDetails[0]['no_of_parts']} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Repairs</label>
                                    <p> {claimDetails[0]['no_of_repairs']} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Total Claims</label>
                                    <p> {claimDetails[0]['total_claim']} </p>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>

                {/* <div className="card"> */}
                {/* <div className="card-body"> */}
                <div className="Repairs_info">
                    {/* <div className="subName">
                                <p>Repairs</p>
                            </div> */}
                    {/* <div>
                                {repairList.map((repairs) => {
                                    <p> {repairs}</p>
                                })}
                            </div> */}
                    <div >
                        {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {repairList.map((field, indexes) => {
                                        return (
                                            <>
                                                <li className="nav-item" role="presentation" key={field.id} >
                                                    <button className={indexes === 0 ? 'nav-link active' : 'nav-link'} id={`pills-${indexes}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${indexes}`} type="button" role="tab" aria-controls={`pills-${indexes}`} aria-selected="true" >Repair {indexes + 1}
                                                    </button>
                                                </li>
                                            </>
                                        )
                                    })}
                                </ul> */}
                        <div >
                            {repairList.map((field, indexes) => {
                                return (
                                    <div className="card multicard" key={indexes}>
                                        <div className="card-body repParts">
                                            <div className="repair_count"> <p>Repair {indexes + 1}</p></div>
                                            <div className="row">
                                                {/* <div className="col-md-4">
                                                    <label>Category</label> <br />
                                                    <p>{field.category}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Gate Type</label>
                                                    <p>{field.gate_type}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Component</label>
                                                    <p>{field.component}</p>
                                                </div>
                                                <div className="subName">
                                                    <p>Labor</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Hours</label>
                                                    <p>{field.labour_hours}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Rate</label>
                                                    <p>{field.labour_rate}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Total</label>
                                                    <p>{field.labour_total}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Misc charge</label>
                                                    <p>{field.misc_charge}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Misc Description</label>
                                                    <p>{field.misc_description}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Repair Description</label>
                                                    <p>{field.repair_Description}</p>
                                                </div> */}
                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label>Category</label> <br />
                                                            <p>{field.category}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Gate Type</label>
                                                            <p>{field.gate_type}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Component</label>
                                                            <p>{field.component}</p>
                                                        </div>
                                                        <div className="col-md-4 mic_desc">
                                                            <label>Misc Description</label> <br />
                                                            {/* <p style={isOpen ? null : paragraphStyles} ref={ref}>
                                                                {field.misc_description}
                                                            </p>
                                                            {
                                                                showReadMoreButton && (
                                                                    <p className="readMore" onClick={() => setIsopen(!isOpen)}>
                                                                        {isOpen ? 'Read Less' : "Read More"}
                                                                    </p>
                                                                )
                                                            } */}
                                                            {(() => {
                                                                if (field.misc_description != "") {
                                                                    return <div>
                                                                        <p className="text">
                                                                            {isReadMore ? field.misc_description?.substring(0, 100) : field.misc_description}
                                                                            {field.misc_description?.length > 100 &&
                                                                                <span
                                                                                    onClick={toggleReadMore}
                                                                                    className="read-or-hide"
                                                                                    style={{ color: "dodgerblue", cursor: "pointer" }}
                                                                                >
                                                                                    {isReadMore ? "...Read more" : " Read less"}
                                                                                </span>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                } else {
                                                                    return "-"
                                                                }
                                                            })()}

                                                            {/* {isReadMore ? field.misc_description?.substring(0, 100) : field.misc_description}
                                                            <span
                                                                onClick={toggleReadMore}
                                                                className="read-or-hide"
                                                                style={{ color: "dodgerblue", cursor: "pointer" }}>
                                                                {isReadMore ? "...Read more" : " Show less"}
                                                            </span> */}
                                                        </div>
                                                        <div className="col-md-4 mic_desc">
                                                            <label>Repair Description</label> <br />
                                                            {/* <p style={isRepairOpen ? null : paragraphStyles} ref={reff}>
                                                                {field.repair_Description}
                                                            </p>
                                                            {
                                                                showReadMoreRepair && (
                                                                    <p className="readMore" onClick={() => setIsRepairOpen(!isRepairOpen)}>
                                                                        {isRepairOpen ? 'Read Less' : "Read More"}
                                                                    </p>
                                                                )
                                                            } */}
                                                            {(() => {
                                                                if (field.repair_Description != "") {
                                                                    return <div>
                                                                        {repairReadMore ? field.repair_Description?.substring(0, 100) : field.repair_Description}
                                                                        {field.repair_Description?.length > 100 &&
                                                                            <span
                                                                                onClick={toggleRepReadMore}
                                                                                className="read-or-hide"
                                                                                style={{ color: "dodgerblue", cursor: "pointer" }}>
                                                                                {repairReadMore ? "...Read more" : " Read less"}
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                } else {
                                                                    return "-"
                                                                }
                                                            })()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="labor_content">
                                                        <div className="subName_lab">
                                                            <p>LABOR</p>
                                                        </div>
                                                        <hr />
                                                        <div className="lab_box_data">
                                                            <div className="lab_data">
                                                                <label>Hours</label>
                                                                <p>{field.labour_hours} hrs</p>
                                                            </div>
                                                            <div className="lab_data">
                                                                <label>Rate</label>
                                                                <p>$ {field.labour_rate}</p>
                                                            </div>
                                                            <div className="lab_data">
                                                                <label>Total</label>
                                                                <p>$ {field.labour_total}</p>
                                                            </div>
                                                            <div className="lab_data">
                                                                <label>Misc charge</label>

                                                                {(() => {
                                                                    if (field.misc_charge === "") {
                                                                        return <p>$ -</p>;
                                                                    } else {
                                                                        return <p>$ {field.misc_charge}</p>
                                                                    }
                                                                })()}
                                                            </div>
                                                            <div className="lab_data">
                                                                <label>Repair Total</label>
                                                                <p>$ {field.repair_parts_total}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="partsHead">Parts</p>
                                            <div className="parts_all">
                                                {
                                                    partList.map((parts, index) => {
                                                        if (indexes + 1 === parts.repair_no) {
                                                            return (
                                                                // <div className="parts_total" key={parts.part_count}>

                                                                //     {/* <div> */}
                                                                //         <p className="parts_count">Part {parts.part_count}</p>
                                                                //         <div className="row">
                                                                //             <div className="col-md-4">
                                                                //                 <label>Part Number</label>
                                                                //                 <p>{parts.part_no}</p>
                                                                //             </div>
                                                                //             <div className="col-md-4">
                                                                //                 <label>Part Description</label>
                                                                //                 <p>{parts.parts_description}</p>
                                                                //             </div>
                                                                //             <div className="col-md-4">
                                                                //                 <label>Quantity Claimed</label>
                                                                //                 <p>{parts.qty_claimed}</p>
                                                                //             </div>
                                                                //             <div className="col-md-4">
                                                                //                 <label>Unit Price Claimed</label>
                                                                //                 <p>{parts.unit_price_claimed}</p>
                                                                //             </div>
                                                                //             <div className="col-md-4">
                                                                //                 <label>Total</label>
                                                                //                 <p>{parts.total}</p>
                                                                //             </div>
                                                                //         </div>
                                                                // </div>

                                                                <div className="parts_total" key={parts.part_count}>

                                                                    {/* <div> */}

                                                                    <div className="parts_data">
                                                                        <p className="parts_count">Part {parts.part_count}</p>
                                                                        <div className="count-details">
                                                                            <label>Part Number</label>
                                                                            <p>{parts.part_no}</p>
                                                                        </div>
                                                                        <div className="count-details">
                                                                            <label>Part Description</label> <br />
                                                                            {(() => {
                                                                                if (parts.parts_description === "") {
                                                                                    return '-';
                                                                                } else {
                                                                                    return <p className="prt_desc">{parts.parts_description}</p>
                                                                                }
                                                                            })()}

                                                                        </div>
                                                                        <div className="count-details">
                                                                            <label>Quantity Claimed</label> <br />
                                                                            {(() => {
                                                                                if (parts.qty_claimed === "") {
                                                                                    return '-';
                                                                                } else {
                                                                                    return <p>{parts.qty_claimed}</p>
                                                                                }
                                                                            })()}
                                                                            {/* <p>{parts.qty_claimed}</p> */}
                                                                        </div>
                                                                        <div className="count-details">
                                                                            <label>Unit Price Claimed</label><br />
                                                                            {(() => {
                                                                                if (parts.unit_price_claimed === "") {
                                                                                    return '-';
                                                                                } else {
                                                                                    return <p>$ {parts.unit_price_claimed}</p>
                                                                                }
                                                                            })()}
                                                                            {/* <p>{parts.unit_price_claimed}</p> */}
                                                                        </div>
                                                                        <div className="count-details">
                                                                            <label>Total</label>
                                                                            <p>$ {parts.total}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="home_footer claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser"  >
                            <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default ClaimDetails