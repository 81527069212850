import React from "react";
import ReturnMenu from "./returnsmenu";
import '../pages/claimDetails.css';
import { useEffect, useRef } from "react";
import supabase from "../api/supabaseconnect.js";
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useParams } from "react-router-dom";
import GIFLOADER from '../assets/loader.gif';
import back_icon from "../assets/back_icon.png"
import moment from "moment";
import { get_rma_details_api } from "../api/rma_details_api.js";
import newlogout from '../assets/logoutblack.svg';
import LEFTMENU from "./leftMenu.js";


function Rmadetails() {

    const { id } = useParams()
    const ref = useRef(null);
    const [session, setSession] = useState(null)
    var [btndis, SetBtnDisable] = useState(false);
    const [claimDetails, SetClaimDetails] = useState([{}]);
    const [web_date, setWeb_date] = useState();
    const [maxacc, setaccno] = useState();
    const [rmastatus, setrmastatus] = useState();
    const [rmausername, setUsername] = useState();
    const [rmausermail, setUsermail] = useState();
    const [rmanumber, setrmanumber] = useState();
    const [rmacreatedby, setrmacreatedby] = useState();
    const [creditmemo, setCreditMeno] = useState();
    const [epicorComments, setEpicorComments] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        // window.history.forward();
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
            // console.log(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        var response_data = get_rma_details_api(id).then(res => {
            console.log(res);
            setrmanumber(res.data[0].rma_no)
            setrmacreatedby(moment(res.data[0].created_at).format("MM-DD-YYYY"))
            setEpicorComments(res.epicor_comments)
            setCreditMeno(res.credit_memo_no)
            setUsername(res.created_by)
            setUsermail(res.email_address)
            SetClaimDetails(res.data);
            setaccno(res.maxon_Account_no)
            setrmastatus(res.rma_status)
            SetBtnDisable(true);
            setWeb_date(moment(res.return_date).format("MM-DD-YYYY"))
        })
        return () => subscription.unsubscribe()
    }, [])
    function backToRMA() {
        localStorage.setItem('fromdetails', 'pagecount')
    }

    return (
        <div className="details_Total">
            <LEFTMENU />
            <div className="container details_All">
                <div className="claim_top_bar">
                    <div className="ret_top">
                        <h3> RMA Details</h3>
                        <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <div className="logImg"><img src={newlogout}></img></div>
                            <div className="warrntyp" >
                                <p>LOG OUT</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                <div className="previous_icon rtn_details" onClick={backToRMA}>
                    <Link to={"/returnslist"}>
                        <img src={back_icon} />
                        <span>  Back to RMA List</span>
                    </Link>
                </div>
                <div className="baic_info_details">
                    <div className="subName">
                        <p>Basic Information</p>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label>RMA Number </label>
                                    <p>{rmanumber}</p>
                                </div>
                                <div className="col-md-4">
                                    <label>Maxon Account</label>
                                    <p>{maxacc}</p>
                                </div>
                                <div className="col-md-4">
                                    <label>User Name </label>
                                    <p>{rmausername}</p>
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-md-4">
                                    <label>Created By </label>
                                    <p>{rmausername}</p>
                                </div> */}
                                <div className="col-md-4">
                                    <label>User Email </label>
                                    <p>{rmausermail}</p>
                                </div>
                                <div className="col-md-4">
                                    <label>Created At
                                    </label>
                                    <p>{web_date} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Status</label>
                                    {/* <p>{rmastatus}</p> */}
                                    {(() => {
                                        if (rmastatus === "Waiting on Return") {
                                            return <p className="admin_wait_color">Waiting on Return</p>
                                        } else if (rmastatus === "Submitted") {
                                            return <p className="rma_sub_color">Submitted</p>
                                        } else if (rmastatus === "Closed") {
                                            return <p className="rma_sub_color">Closed</p>
                                        } else {
                                            return <p>{rmastatus}</p>
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Credit Memo# </label>
                                    <p>{creditmemo} </p>
                                </div>
                                <div className="col-md-4">
                                    <label>Maxon Comments </label>
                                    <p>{epicorComments} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="subName prodoinfo">
                        <p>Product Information</p>
                    </div>
                    <div>
                        {claimDetails.map((field, indexes) => {
                            return (
                                <div className="card multicard retdetails" key={indexes}>
                                    <div className="card-body repParts">
                                        <div className="repair_count"> <p>Part {indexes + 1}</p></div>
                                        {/* <div className="row">
                                            <div className="col-md-4">
                                                <label>RMA Number </label>
                                                <p>{field.rma_no}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Maxon Account</label>
                                                <p>{maxacc}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Status</label>
                                                <p>{rmastatus}</p>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Maxon Invoice #</label><br />
                                                {(() => {
                                                    if (field.invoice_no === "") {
                                                        return '-';
                                                    } else {
                                                        return <p>{field.invoice_no}</p>
                                                    }
                                                })()}
                                            </div>
                                            <div className="col-md-4">
                                                <label>Part Number
                                                </label>
                                                <p>{field.part_no}</p>
                                            </div>

                                            <div className="col-md-4">
                                                <label>Part Description </label> <br />
                                                {(() => {
                                                    if (field.part_description === "") {
                                                        return '-';
                                                    } else {
                                                        return <p>{field.part_description}</p>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Quantity </label><br />
                                                {(() => {
                                                    if (field.return_qty === "") {
                                                        return '-';
                                                    } else {
                                                        return <p>{(field.return_qty)}</p>
                                                    }
                                                })()}
                                            </div>
                                            <div className="col-md-4">
                                                <label>Reason Code</label>
                                                {(() => {
                                                    if (field.reason_code === '1A') {
                                                        return <p >Customer Error</p>;
                                                    } else if (field.reason_code === '5A') {
                                                        return <p >Maxon Error</p>;
                                                    } else if (field.reason_code === '1D') {
                                                        return <p >Overstock</p>;
                                                    }
                                                })()}

                                            </div>
                                            <div className="col-md-4">
                                                <label>Replacements required
                                                </label>
                                                <p>{field.replace_required}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Pick up arrangements </label>
                                                <p>{field.pickup_arrangements}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Comments</label>
                                                <p>{field.comments}</p>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <label>Created At
                                                </label>
                                                <p>{moment(field.created_at).format("MM-DD-YYYY")} </p>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-4">
                                                <label>RMA Number </label>
                                                <p>{field.rma_no}</p>
                                            </div> */}
                                            {/* <div className="col-md-4">
                                                <label>Maxon Account</label>
                                                <p>{maxacc}</p>
                                            </div> */}
                                            {/* <div className="col-md-4">
                                                <label>Status</label>
                                                <p>{rmastatus}</p>
                                            </div> */}
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-4">
                                                <label>Created By </label>
                                                <p>{rmausername}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label>User Email </label>
                                                <p>{rmausermail}</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Rmadetails