import React from "react";
import "../pages/claims.css"
import search_icon from '../assets/search_icon.png'
import LEFTMENU from "./leftMenu";
import claim_list_api from '../api/claim_list_api';
import { useEffect, useState } from "react";
import supabase from "../api/supabaseconnect.js";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import edit_icon from "../assets/editing.png"
import GIFLOADER from '../assets/loader.gif';
import moment from "moment";
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Topmenu from "./topmenu.js";
import delete_icon from '../assets/delete.png';
import { delete_claim_api } from '../api/delete_claim_api.js';
import filternew from '../assets/filternew.svg';
import addnew from '../assets/addnew.svg'
import newlogout from '../assets/logoutblack.svg';
import { logout_api } from '../api/logout_api.js';

function CLAIMS() {


    var [suchidden, ShowSuc] = useState(false);
    var [errhidden, ShowErr] = useState(true);
    var [btndis, SetBtnDisable] = useState(false);
    var [suMSG, SetSuMsg] = useState('');
    const [claims, SetClaims] = React.useState([]);
    const [session, setSession] = React.useState(null)
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setrecordsPerPage] = useState(5);
    const [items_per_page, setItems_per_page] = useState(0);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = claims.slice(firstIndex, lastIndex);
    const npage = Math.ceil(claims.length / recordsPerPage);
    // const pnumbers = [...Array(npage + 1).keys()].slice(1);
    const [order, setOrder] = useState("ASC");
    const [searchValue, setSearchValue] = useState("")
    const [show, setShow] = useState(false);
    const [popshow, setPopShow] = useState(false);
    const [toggle, SetToggle] = useState(1);
    const [dates, SetDates] = useState([]);
    const [filterDate, SetfilterDate] = useState([]);
    const [filterStatus, SetfilterStatus] = useState({})
    const { register, watch, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [claimid, setClmid] = useState();
    const [filterSearch, SetfilterSearch] = useState('');
    const isEmpty = claims.length === 0;
    const [activeyear, setActiveYear] = useState();


    const onError = (errors) => {
        console.log(errors)
    }
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const filterSubmit = (req_data) => {
        document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
        console.log(req_data)

        localStorage.setItem('claim_page_start', 0)
        localStorage.setItem('claim_curr_page', 1)

        setItems_per_page(0)
        setCurrentPage(1)
        SetfilterStatus(req_data)
        SetfilterDate(dates);
        setShow(!show)
        SetBtnDisable(false);
        dates.push(startDate)
        dates.push(endDate)
        console.log(dates)
        var response_data = claim_list_api(req_data, dates, '', '', '').then(res => {
            SetClaims(res.data);
            SetBtnDisable(true);
            SetDates([])
            localStorage.setItem('filterdata', JSON.stringify(req_data))
            if (dates != undefined && dates != '' && dates != null && dates[0] != undefined && dates[1] != undefined) {
                localStorage.setItem('fildate', dates)
            }

            // localStorage.setItem('filterdata', req_data);
            // if (req_data.allclaim == true) {
            //     localStorage.setItem('allclaims', JSON.stringify(res.data))
            // }
        })

    }

    function ClearFilter() {
        reset();
        document.getElementById("divsection").style.backgroundColor = "white";
        localStorage.removeItem('filterdata');
        localStorage.removeItem('fildate');
        localStorage.setItem('claim_page_start', 0)
        localStorage.setItem('claim_curr_page', 1)
        setShow(!show)
        SetBtnDisable(false);
        // SetDates([])
        console.log(filterStatus);
        SetfilterStatus({});
        SetfilterDate([]);
        setCurrentPage(1)
        setItems_per_page(0)
        setStartDate()
        setEndDate()
        var response_data = claim_list_api('', '', '', '', '').then(res => {
            SetClaims(res.data);
            SetBtnDisable(true);
            // setStartDate(new Date())
        })
    }
    function updateToggle(id) {
        SetToggle(id);
    }
    useEffect(() => {
        // window.history.forward();
        window.scrollTo(0, 0);
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        var countfilteritm = JSON.parse(localStorage.getItem('filterdata'));
        console.log(countfilteritm)
        if (countfilteritm != undefined && countfilteritm != '' && countfilteritm != null) {
            SetfilterStatus(countfilteritm)
            // setChecked(!checked)
        } else {
            countfilteritm = ''
        }

        if (countfilteritm && countfilteritm.allclaim === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('allclaim', countfilteritm.allclaim === true)
        }
        if (countfilteritm && countfilteritm.rejected === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('rejected', countfilteritm.rejected === true)
        }
        if (countfilteritm && countfilteritm.closed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('closed', countfilteritm.closed === true)
        }
        if (countfilteritm && countfilteritm.entryhold === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('entryhold', countfilteritm.entryhold === true)
        }
        if (countfilteritm && countfilteritm.approved === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('approved', countfilteritm.approved === true)
        }
        if (countfilteritm && countfilteritm.submitted === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('submitted', countfilteritm.submitted === true)
        }
        if (countfilteritm && countfilteritm.draft === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('draft', countfilteritm.draft === true)
        }
        if (countfilteritm && countfilteritm.error === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('error', countfilteritm.error === true)
        }
        if (countfilteritm && countfilteritm.failed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('failed', countfilteritm.failed === true)
        }
        if (countfilteritm && countfilteritm.processingcheck === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('processingcheck', countfilteritm.processingcheck === true)
        }
        if (countfilteritm && countfilteritm.processingcreditmemo === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('processingcreditmemo', countfilteritm.processingcreditmemo === true)
        }
        var splitdate = [];
        var filterdate = (localStorage.getItem('fildate'));
        console.log(filterdate)
        if (filterdate != undefined && filterdate != '' && filterdate != null) {
            var splitdate = filterdate.split(',');
            console.log(splitdate)
            setStartDate(splitdate[0]);
            setEndDate(splitdate[1]);
            SetfilterDate(splitdate)
            if (splitdate != []) {
                document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            }
            // setChecked(!checked)
        } else {
            filterdate = []
        }
        var response_data = claim_list_api(countfilteritm, splitdate, '', '', '').then(res => {
            console.log(res.data)
            const dateObj = new Date();
            const year = dateObj.getFullYear();
            localStorage.setItem('currentDate', year)
            setActiveYear(year)
            SetClaims(res.data);
            SetBtnDisable(true);
            var crtpage = localStorage.getItem('fromdetails')
            var countperpage = localStorage.getItem('currentpage');
            if (crtpage == 'pagecount' && countperpage != undefined && countperpage != '') {
                // setrecordsPerPage([])
                setrecordsPerPage(countperpage);
            }

            var claim_page_start = localStorage.getItem('claim_page_start');
            var claim_curr_page = localStorage.getItem('claim_curr_page');
            if (claim_page_start != undefined && claim_page_start != '' && claim_curr_page != undefined && claim_curr_page != '') {
                setItems_per_page(parseInt(claim_page_start));
                setCurrentPage(parseInt(claim_curr_page));
            }
        })
        return () => subscription.unsubscribe()
    }, [searchValue === ""])
    const logoutUser = async () => {
        var response_data = await logout_api();
        // console.log(response_data);
        navigate("/");
        SetBtnDisable(btndis = false);
        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                localStorage.clear();
                ShowSuc(suchidden = true);

            }, 3000);
            SetBtnDisable(btndis = true);
        }
        // else {
        //     SetErMsg(suMSG = response_data.msg);
        //     ShowErr(!errhidden);
        //     setTimeout(() => {
        //         ShowErr(errhidden = true);
        //     }, 3000);
        //     SetBtnDisable(btndis = true);
        // }
    }
    // PrePagination
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            var start_ind = (currentPage - 2) * recordsPerPage;
            setItems_per_page(start_ind)
            localStorage.setItem('claim_page_start', start_ind)
            localStorage.setItem('claim_curr_page', (currentPage - 1))
        }
    }
    // currentPage
    function changeCurPage(id) {
        console.log(id)
        var start_ind = (id - 1) * recordsPerPage;
        setItems_per_page(start_ind)
        setCurrentPage(id)
    }
    // nextPage
    function nextPage() {
        if (currentPage !== npage) {
            var start_ind = currentPage * recordsPerPage;
            setItems_per_page(start_ind)
            setCurrentPage(currentPage + 1)
            localStorage.setItem('claim_page_start', start_ind)
            localStorage.setItem('claim_curr_page', (currentPage + 1))
        }
    }
    function details_page(res, status) {
        console.log(status);
        // return navigate(`/claimDetails/${res}`);
        if (status == "0") {
            return navigate(`/editclaim/${res}`);
        } else {
            return navigate(`/claimDetails/${res}`);
        }

    }
    function editPage(evt) {
        navigate(`/editclaim/${evt}`);
        return true
    }
    function deleteclaim(evt) {
        // console.log(evt)
        setClmid(evt)
        setPopShow(!popshow)
    }
    function staysamepage() {
        setPopShow(!popshow)
    }
    function deleteClaim() {
        var response_data = delete_claim_api(claimid).then(res => {
            console.log(res)
            SetBtnDisable(false);
            SetSuMsg(res.msg)
            // setCurrentPage(1)
            // setItems_per_page(0)
            setPopShow(!popshow)
            ShowSuc(true)
            setTimeout(() => {
                ShowSuc(false)
                SetSuMsg('')
                SetBtnDisable(true);
            }, 3000);
            var response_data = claim_list_api(filterStatus, filterDate, '', '', '').then(res => {
                // console.log(res.data)
                SetClaims(res.data);
                // SetBtnDisable(true);
            })
        })
    }
    const sorting = (col) => {
        console.log(col)
        setCurrentPage(1)
        setItems_per_page(0)
        localStorage.setItem('claim_page_start', 0)
        localStorage.setItem('claim_curr_page', 1)
        console.log(filterStatus);
        // setOrder('ASC');
        if (order === 'ASC') {
            var response_data = claim_list_api(filterStatus, filterDate, col, order, filterSearch).then(res => {
                SetClaims(res.data)
            })
            setOrder('DSC');
        }
        if (order === 'DSC') {
            var response_data = claim_list_api(filterStatus, filterDate, col, order, filterSearch).then(res => {
                SetClaims(res.data)
            })
            setOrder('ASC');
        }
    }
    function pageChange(evt) {
        setCurrentPage(1);
        setItems_per_page(0)
        setrecordsPerPage(evt.target.value);
        localStorage.setItem('currentpage', evt.target.value)
        localStorage.setItem('claim_page_start', 0)
        localStorage.setItem('claim_curr_page', 1)
    }

    var search_res = watch('user_search')
    useEffect(() => {
        // SetfilterSearch(searchResult)
        setCurrentPage(1);
        setItems_per_page(0)
        // console.log(result.target.value);
        const delayApiCall = setTimeout(() => {
            var response_data = claim_list_api(filterStatus, filterDate, '', '', search_res).then(res => {
                SetClaims(res.data)
            })
        }, 1000);
        return () => clearTimeout(delayApiCall);
    }, [search_res])

    return (
        <div className="sideMenu">
            <LEFTMENU />
            <div className="container details_All">
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                {suchidden && < div className='toaster suc returns_suc'>
                    <h2>{suMSG}</h2>
                </div>}
                <div className="claim_top_bar">
                    <div className=" user_topbar">
                        <h3>Warranty Claims</h3>
                        <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <div className="logImg"><img src={newlogout}></img></div>
                            <div className="warrntyp" >
                                <p>LOG OUT</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="claim_top_btns rma_top_btns">
                    <div className="control_search">
                        <form className="search-area">
                            <img src={search_icon} alt="search-icon" />
                            {/* <input type="text"
                                placeholder="Search..." onChange={(e) => searchResults(e.target.value)} /> */}
                            <input type="text"
                                placeholder="Search..."{...register('user_search')} />
                        </form>
                    </div>
                    <div className="claimBtns">

                        <button className="filter_btnn" onClick={() => setShow(!show)} id="divsection">
                            <img src={filternew}></img>
                            Filter
                        </button>
                        <Link to="/addclaim">  <button className="claim_btn"> <img src={addnew}></img>
                            Add Claim</button></Link>
                    </div>
                </div>
                <div>
                    {
                        popshow && <div className="CanPop">
                            <div className="can_popTotal">
                                <div className='can_title'>
                                    Confirmation
                                </div>
                                <hr />
                                <div className='cancel_content'>
                                    <p>
                                        Are you sure you want to delete the claim?
                                    </p>
                                </div>
                                <hr />

                                <div className='cancel_btns'>
                                    <button className='can_no' onClick={staysamepage}>No</button>
                                    <button className='can_yes' onClick={deleteClaim}>Yes</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="filter_popTotal">
                    {
                        show && <div className="claimPop filres">
                            <div className="claim-popTotal">
                                <div className="popSubclass">
                                    <div className="claim-pop_left">
                                        <div>
                                            <ul>
                                                <li onClick={() => updateToggle(1)}>Status</li>
                                                <li onClick={() => updateToggle(2)}>Date</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="claim-pop_right">
                                        <form onSubmit={handleSubmit(filterSubmit, onError)}>
                                            <div className={toggle === 1 ? "show-content" : "content"}>

                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" id="draft"  {...register("draft")} />
                                                    <label htmlFor="draft" className="form-check-label" >
                                                        Draft
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="submitted" {...register("submitted")} />
                                                    <label htmlFor="submitted" className="form-check-label" >
                                                        Submitted
                                                    </label>
                                                </div>
                                                {/* <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="approved" {...register("approved")} />
                                                    <label htmlFor="approved" className="form-check-label" >
                                                        Approved
                                                    </label>
                                                </div> */}
                                                {/* <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="entryhold" {...register("entryhold")} />
                                                    <label htmlFor="entryhold" className="form-check-label" >
                                                        Entry Hold
                                                    </label>
                                                </div> */}
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="closed" {...register("closed")} />
                                                    <label htmlFor="closed" className="form-check-label" >
                                                        Closed
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="rejected" {...register("rejected")} />
                                                    <label htmlFor="rejected" className="form-check-label" >
                                                        Rejected
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="error" {...register("error")} />
                                                    <label htmlFor="error" className="form-check-label" >
                                                        Error
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="failed" {...register("failed")} />
                                                    <label htmlFor="failed" className="form-check-label" >
                                                        Failed
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="processingcheck" {...register("processingcheck")} />
                                                    <label htmlFor="processingcheck" className="form-check-label" >
                                                        Processing Check
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="processingcreditmemo" {...register("processingcreditmemo")} />
                                                    <label htmlFor="processingcreditmemo" className="form-check-label" >
                                                        Processing Credit Memo
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox"
                                                        id="allclaimfil"  {...register("allclaim")} />
                                                    <label htmlFor="allclaimfil" className="form-check-label" >
                                                        All Claims
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={toggle === 2 ? "show-content" : "content"}>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <div className="filter_btns ">
                                                <button className="clr_btn" onClick={ClearFilter}>Clear</button>
                                                <button className="filCan" onClick={() => setShow(!show)}>Cancel </button>
                                                <button type="submit" className="addclaim-sub-btn"  >Apply </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="claims_card">
                    {/* <div className="card">
                        <div className="card-body"> */}
                    <div className="claims_table table-responsive">
                        {isEmpty ? (
                            <p className="display_text">No claims to show</p>
                        ) : (
                            <table className="table desc_table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" onClick={() => sorting('created_at')}>
                                            <b>Claim ID</b> <i className="fa fa-fw fa-sort"></i></th>
                                        <th scope="col" onClick={() => sorting('invoice_no')}>
                                            <b>Invoice #</b> <i className="fa fa-fw fa-sort"></i></th>
                                        <th scope="col" onClick={() => sorting('model_no')}>
                                            <b>Lift Gate Model</b> <i className="fa fa-fw fa-sort"></i></th>
                                        <th scope="col" onClick={() => sorting('serial_no')}>
                                            <b>Gate Serial #</b> <i className="fa fa-fw fa-sort"></i></th>
                                        <th scope="col" onClick={() => sorting('web_entry_date')}>
                                            <b>Claim Date</b> <i className="fa fa-fw fa-sort"></i></th>
                                        <th scope="col" onClick={() => sorting('status_value')}>
                                            <b>Status</b> <i className="fa fa-fw fa-sort"></i></th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records?.map((res, index) =>
                                        <tr key={index} data-id={res.id} >
                                            <React.Fragment >
                                                <td onClick={() => details_page(res.id, res.status)}>{(items_per_page + index + 1)}</td>
                                                <td onClick={() => details_page(res.id, res.status)}> {res.web_reference_no}</td>
                                                <td onClick={() => details_page(res.id, res.status)}>
                                                    {(() => {
                                                        if (res.invoice_no == '') {
                                                            return '-';
                                                        } else {
                                                            return <p >{res.invoice_no}</p>;
                                                        }
                                                    })()}
                                                </td>
                                                {/* <td onClick={() => details_page(res.id, res.status)}> {res.invoice_no}</td> */}
                                                <td className="lift_model" onClick={() => details_page(res.id, res.status)}>{res.model_no}</td>
                                                <td className="srlnum" onClick={() => details_page(res.id, res.status)}> {res.serial_no}</td>
                                                <td onClick={() => details_page(res.id, res.status)}>
                                                    {moment(res.web_entry_date).format("MM-DD-YYYY")} </td>

                                                <td onClick={() => details_page(res.id, res.status)}>
                                                {(() => {
                                                        if (res.status_value === 'Draft') {
                                                            return <p className="draft_color">Draft</p>;
                                                        } else if (res.status_value === "Submitted") {
                                                            return <div className="sub_color">Submitted
                                                                <span class="tooltiptext-closed">
                                                                <strong>Status: Submitted</strong> <br />
                                                                    Your claim was submitted as is now under review awaiting further processing.</span>
                                                            </div>
                                                            // <p className="sub_color">Submitted</p>;
                                                        } else if (res.status_value === "Rejected") {
                                                            return <p className="del_color">Rejected</p>;
                                                        } else if (res.status_value === "Open") {
                                                            return <p className="draft_color">Open</p>;
                                                        } 
                                                        // else if (res.status_value === "Closed") {
                                                        //     return <div className="sub_color">Closed
                                                        //         <span class="tooltiptext-closed">
                                                        //             <strong>Status: Credit Memo Processed</strong> <br />
                                                        //             Maxon credit
                                                        //             department has created your credit memo. See Claim view for credit memo
                                                        //             number</span>
                                                        //     </div>
                                                        // }
                                                        else if (res.check_no != '' && res.check_no != undefined && res.check_no != null && (res.status_value === "Closed" || res.status_value === "Payment Processed")) {
                                                            return <div className="sub_color">Closed
                                                                <span class="tooltiptext-closed">
                                                                    <strong>Status: Payment Processed</strong> <br />
                                                                    Maxon AP
                                                                    department has processed your check. See claim view for check information</span>
                                                            </div>;
                                                        } else if (res.credit_memo_no != '' && res.credit_memo_no != undefined && res.credit_memo_no != null && (res.status_value === "Closed" || res.status_value === "Credit Memo Processed")) {
                                                            return <div className="sub_color">Closed
                                                                <span class="tooltiptext-closed">
                                                                    <strong>Status: Credit Memo Processed</strong> <br />
                                                                    Maxon credit
                                                                    department has created your credit memo. See Claim view for credit memo
                                                                    number</span>
                                                            </div>;
                                                        } else if (res.status_value === "Failed") {
                                                            return <p className="del_color">Failed</p>;
                                                        } else if (res.status_value === "Error") {
                                                            return <div className="act_img">
                                                                <p className="del_color">Error</p>
                                                            </div>
                                                        } else if (res.status_value === "Processing Check" || res.status_value === "Processing check" || res.status_value === "processing check") {
                                                            return <div className="processcheck_color">Processing Check
                                                                <span class="tooltiptext-closed">
                                                                    <strong>Status: Processing Check</strong> <br />
                                                                    Maxon AP department is processing your check.</span></div>
                                                        } else if (res.status_value === "Processing Credit Memo" || res.status_value === "Processing credit memo" || res.status_value === "processing credit memo") {
                                                            return <div className="processcheck_color">Processing Credit Memo
                                                                <span class="tooltiptext-closed">
                                                                    <strong>Status: Processing Credit Memo</strong> <br />
                                                                    Maxon credit department has your claims and is working on your credit memo.</span></div>
                                                        } else if (res.status_value === "Closed") {
                                                            return <p className="sub_color">Closed</p>;
                                                        } else if (res.status_value === "Approved") {
                                                            return <p className="sub_color">Approved</p>;
                                                        } else {
                                                            return <p className="">{res.status_value}</p>
                                                        }
                                                    })()}
                                                </td>
                                            </React.Fragment>
                                            <td >
                                                {(() => {
                                                    if (res.status === 0) {
                                                        return <div className="act_img">
                                                            <img src={edit_icon} onClick={() => editPage(res.id)} />
                                                            <img src={delete_icon} onClick={() => deleteclaim(res.id)} />
                                                        </div>

                                                    } else {
                                                        return <p onClick={() => details_page(res.id, res.status)}>View</p>
                                                    }
                                                })()}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>


                        )}
                        <div className="mob_card">
                            <div className="card_data">
                                {records?.map((res, index) =>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="Card_top">
                                                <div>
                                                {(() => {
                                                        if (res.status_value === "Draft") {
                                                            return <p className="draft_color">
                                                                <ul> <li>
                                                                    Draft
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Submitted") {
                                                            return <p className="sub_color">
                                                                <ul><li>
                                                                    Submitted
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Rejected") {
                                                            return <p className="del_color">
                                                                <ul className="del_color"><li>
                                                                    Rejected
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Open") {
                                                            return <p className="draft_color">
                                                                <ul> <li>
                                                                    Open
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Closed" || res.status_value === "Credit Memo Processed" || res.status_value === "Processing Check") {
                                                            return <p className="sub_color">
                                                                <ul><li>
                                                                    Closed
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Approved") {
                                                            return <p className="sub_color">
                                                                <ul><li>
                                                                    Approved
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Failed") {
                                                            return <p className="del_color">
                                                                <ul><li>
                                                                    Failed
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Error") {
                                                            return <p className="del_color">
                                                                <ul><li>
                                                                    Error
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Processing Check") {
                                                            return <p className="processcheck_color">
                                                                <ul><li>
                                                                    Processing Check
                                                                </li></ul>
                                                            </p>;
                                                        } else if (res.status_value === "Processing Credit Memo") {
                                                            return <p className="processcheck_color">
                                                                <ul><li>
                                                                    Processing Credit Memo
                                                                </li></ul>
                                                            </p>;
                                                        } else {
                                                            return <p>{res.status_value}</p>
                                                        }
                                                    })()}
                                                </div>
                                                <div className="edit_icon_card">
                                                    {(() => {
                                                        if (res.status === 0) {
                                                            return <div>
                                                                <img src={edit_icon} onClick={() => editPage(res.id)} ></img>
                                                                <img src={delete_icon} onClick={() => deleteclaim(res.id)} />
                                                            </div>
                                                        } else {
                                                            return <span onClick={() => details_page(res.id, res.status)}>View</span>
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                            {/* <hr></hr> */}
                                            <div className="row card_top"  >
                                                <div className="col-sm">
                                                    <div className="mob_data_lable">
                                                        <label>Claim Id</label>
                                                        {res.web_reference_no}
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="mob_data_lable">
                                                        <label>Claim Date</label>
                                                        {moment(res.web_entry_date).format("MM-DD-YYYY")}
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="row card_top">
                                                <div className="col-sm">
                                                    <div className="mob_data_lable">
                                                        <label>Gate Serial #</label>
                                                        {res.serial_no}
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="mob_data_lable">
                                                        <label>Invoice Number</label>
                                                        {res.invoice_no}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row card_top">
                                                <div className="col">
                                                    <div className="mob_data_lable">
                                                        <label>Lift Gate Model</label>
                                                        {res.model_no}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="claim_pagination claims_page" >
                            <div className="itemsPage custom-select">
                                <p>Items Per Page:</p>
                                <select value={recordsPerPage} className="form-select" onChange={(e) => pageChange(e)}>
                                    <option >5</option>
                                    <option>10</option>
                                    <option>25</option>
                                    <option>50</option>
                                    <option>100</option>
                                </select>
                            </div>
                            <div className="page_wid">
                                <span>Claims({claims.length})</span>
                                <nav >
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a className="page-link"
                                                onClick={prePage}>Prev</a>
                                        </li>
                                        {/* <div> */}
                                        {/* {
                                            pnumbers.map((n, i) => (
                                                <div className="card_pagination">
                                                    <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                                        <a className="page-link"
                                                            onClick={() => changeCurPage(n)}>
                                                            {n}
                                                        </a>
                                                    </li>
                                                </div>
                                            ))
                                        } */}
                                        {/* </div> */}
                                        <li className="page-item">
                                            <a className="page-link"
                                                onClick={nextPage}>Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser" >
                            <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CLAIMS;